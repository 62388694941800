import { useState, useEffect } from 'react'
import { createBasketDescription } from '../order-journeys/_v2/helpers'
import { initialisePaymentIntent } from '../services/payments'

const usePaymentIntent = ({
  amount,
  basket,
  orderTypeDescription,
  receiptEmail,
  currency,
  controller,
}) => {
  const [paymentIntent, setPaymentIntent] = useState(null)

  useEffect(() => {
    const payload = {
      amount,
      currency,
      receiptEmail,
      description: createBasketDescription({ basket, orderTypeDescription }),
    }

    if (amount) {
      initialisePaymentIntent({ payload, controller })
        .then((data) => {
          setPaymentIntent(data)
        })
    }

    return () => controller.abort()
  }, [amount, receiptEmail])

  return paymentIntent
}

export default usePaymentIntent
