import {
  BasketItem as BasketItemComponent,
  Button,
} from '@sh24/ui-components'
import { trackRemoveFromBasket } from '../../order-journeys/_v2/tracking'
import { removeFromBasket } from '../../order-journeys/_v2/helpers'
import replaceTranslationTokens from '../../utils/replace-translation-tokens'

const BasketItem = ({
  item,
  context,
  dispatch,
  translations,
  setRemovedProduct,
}) => {
  const { basket } = context
  const mainItemCount = basket.filter((i) => !i.isExtra).length
  const canRemoveItem = item.isExtra || (!item.mandatory && !item.mutuallyExclusive && mainItemCount > 1)

  const removeHandler = (e) => {
    e.preventDefault()
    setRemovedProduct(item.identifier)
    trackRemoveFromBasket(item.identifier, context)
    dispatch('basket', removeFromBasket({ basket, identifier: item.identifier }))
  }

  const ctaAriaLabel = replaceTranslationTokens({
    translations,
    translationKey: 'orderJourney.removeFromBasket',
    values: [item.name],
  })

  const selectedOption = item.options?.find((o) => o.selected)
  const itemPrice = (selectedOption?.price ? selectedOption?.formattedPrice : null)
    || (item.price ? item.formattedPrice : null)

  const selectedOptions = [
    selectedOption?.label,
  ].filter((text) => !!text)

  return (
    <BasketItemComponent
      text={item.productName}
      subText={item.byline}
      selectedOptions={selectedOptions}
      price={itemPrice || null}
      cta={canRemoveItem ? (
        <Button
          text={translations.remove}
          ariaLabel={ctaAriaLabel}
          onClick={removeHandler}
          variation="text"
          small
        />
      ) : null}
    />
  )
}

export default BasketItem
