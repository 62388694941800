import { TextField, OrderJourneyContent } from '@sh24/ui-components'
import getQuestionInfo from '../../utils/get-question-info'

const NumberQuestion = ({
  question,
  contextKey,
  context,
  dispatch,
}) => {
  const value = context[contextKey] || ''
  const errors = context.errors?.[contextKey] || []
  const { infoTop, infoBottom } = getQuestionInfo(context, question)
  const handler = (e) => {
    dispatch(contextKey, e.target.value)
  }

  const width = {
    mobile: 'sm',
    tablet: 'sm',
    desktop: 'sm',
  }

  return (
    <OrderJourneyContent>
      <TextField
        id={question.id}
        title={question.title}
        autoComplete={question.autoComplete}
        label={question.label}
        hideLabel={question.hideLabel}
        placeholder={question.placeholder}
        type="number"
        inputMode="numeric"
        infoTop={infoTop}
        infoBottom={infoBottom}
        value={value}
        onChange={handler}
        errors={errors}
        width={width}
      />
    </OrderJourneyContent>
  )
}

export default NumberQuestion
