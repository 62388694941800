import Head from 'next/head'
import { useRouter } from 'next/router'
import {
  Page,
  OrderJourneyContent,
  ButtonLink,
  Icon,
  BrandLogo,
  withModal,
  BasicModal,
  Button,
  FlexRow,
} from '@sh24/ui-components'
import styled from 'styled-components'
import Image from '../components/Image/image'
import documentToReactComponents from '../utils/document-to-react-components'
import { useAppContext } from '../contexts/app-context'
import { createSessionKey } from '../order-journeys/_v2/helpers'
import { clearSession } from '../order-journeys/_v2/state/actions'
import SiteNames from './site-names'

const LogoButton = styled.button`
  border: 0 none;
  padding: 0;
  margin: 0;
  background-color: transparent;
  cursor: pointer;
`

const ConfirmCancel = ({
  closeModal,
  closeText,
  confirmText,
  content,
  redirect,
  ctaRef,
}) => {
  const router = useRouter()
  const { slug } = router.query
  const sessionKey = createSessionKey({ slug })

  const onClick = (e) => {
    e.preventDefault()
    clearSession({ sessionKey })
    window.location.assign(redirect)
  }

  const cta = (
    <ButtonLink fullWidthOnMobile animation="shiftRight" iconPosition="right">
      <a href={redirect} onClick={onClick} ref={ctaRef} className="link cancel-order-link">
        {confirmText}
        <Icon name="arrow-right" />
      </a>
    </ButtonLink>
  )

  return (
    <BasicModal
      closeText={closeText}
      closeModal={closeModal}
      content={content}
      confirmCta={cta}
    />
  )
}

const BrandLogoButton = ({
  openModal, openerRef, site, translations,
}) => (
  <LogoButton
    type="button"
    onClick={openModal}
    aria-label={`${SiteNames[site]} ${translations?.returnHome}`}
    ref={openerRef}
  >
    <BrandLogo site={site} />
  </LogoButton>
)

const CancelOrderButton = ({ openModal, openerRef, translations }) => (
  <Button
    variation="text"
    text={translations['orderJourney.cancelOrder'] || null}
    iconName="cross"
    onClick={openModal}
    buttonRef={openerRef}
  />
)

const OrderJourneyLayout = ({ site, children }) => {
  const { query } = useRouter()
  const { appContext } = useAppContext()
  const {
    cancelOrderImage,
    cancelOrderContent,
    translations,
    preview,
  } = appContext
  const cancelOrderComponents = [
    cancelOrderImage ? (
      <div className="mb-sm" key="cancel-order-image">
        <Image
          image={cancelOrderImage}
          maxWidth={100}
          maxHeight={120}
          layout="intrinsic"
        />
      </div>
    ) : null,
    documentToReactComponents(cancelOrderContent),
  ]

  const BrandLogoWithModal = withModal(BrandLogoButton, ConfirmCancel, site, translations)
  const CancelOrderWithModal = withModal(CancelOrderButton, ConfirmCancel, site, translations)

  return (
    <Page sidebar={false} preview={preview}>
      <Head>
        <link rel="icon" href={`/${process.env.NEXT_PUBLIC_SITE}_favicon.ico`} />
      </Head>

      <OrderJourneyContent wide>
        <FlexRow spaced>
          <BrandLogoWithModal
            redirect="/"
            closeText={translations['orderJourney.cancelOrderClose'] || ''}
            confirmText={translations['orderJourney.cancelOrderConfirm'] || ''}
            content={cancelOrderComponents}
          />
          <CancelOrderWithModal
            redirect={query.referrer || '/'}
            closeText={translations['orderJourney.cancelOrderClose'] || ''}
            confirmText={translations['orderJourney.cancelOrderConfirm'] || ''}
            content={cancelOrderComponents}
          />
        </FlexRow>
      </OrderJourneyContent>
      {children}
    </Page>
  )
}

export default OrderJourneyLayout
