import {
  Button,
  Grid,
  ProductSelectionCard,
} from '@sh24/ui-components'
import documentToReactComponents from '../../utils/document-to-react-components'
import useTranslations from '../../utils/use-translations'
import replaceTranslationTokens from '../../utils/replace-translation-tokens'
import { trackAddToBasket, trackRemoveFromBasket } from '../../order-journeys/_v2/tracking'
import {
  inBasket,
  addToBasket,
  removeFromBasket,
  updateProductSelectedOption,
  refreshBasketItem,
} from '../../order-journeys/_v2/helpers'
import Image from '../Image/image'

const ProductSelectionGrid = ({
  products,
  context,
  dispatch,
  setStatusText,
}) => {
  const translations = useTranslations()
  const { basket, products: allProducts } = context

  const removeHandler = (identifier, name) => {
    const statusText = replaceTranslationTokens({
      translations,
      translationKey: 'orderJourney.removedFromBasket',
      values: [name],
    })

    setStatusText(statusText)
    trackRemoveFromBasket(identifier, context)
    dispatch('basket', removeFromBasket({ basket, identifier }))
  }

  const addHandler = (identifier, name) => {
    const statusText = replaceTranslationTokens({
      translations,
      translationKey: 'orderJourney.addedToBasket',
      values: [name],
    })

    setStatusText(statusText)
    trackAddToBasket(identifier, context)
    dispatch('basket', addToBasket({ basket, products, identifier }))
  }

  const updateHandler = (optionIdentifier, identifier) => {
    const updatedProducts = updateProductSelectedOption({ products: allProducts, optionIdentifier, identifier })
    dispatch('products', updatedProducts)

    if (inBasket({ basket, identifier })) {
      dispatch('basket', refreshBasketItem({ basket, products: updatedProducts, identifier }))
    }
  }

  return (
    <Grid width="full-width" mobileColumns="1" tabletColumns="2" desktopColumns="2">
      {products.map((prod) => {
        const selected = inBasket({ basket, identifier: prod.identifier })
        const handler = selected ? removeHandler : addHandler
        const ctaAriaLabel = replaceTranslationTokens({
          translations,
          translationKey: `orderJourney.${selected ? 'removeFromBasket' : 'addToBasket'}`,
          values: [prod.name || prod.productName],
        })

        const selectedOption = prod.options?.find((p) => p.selected)
        const hint = prod.hint || selectedOption?.hint
        const hintIcon = prod.hintIcon || selectedOption?.hintIcon
        const price = (selectedOption?.price ? selectedOption?.formattedPrice : null)
          || (prod.price ? prod.formattedPrice : null)
        const options = prod.options?.map((opt) => ({
          text: opt.label,
          value: opt.identifier,
          selected: opt.selected,
        }))

        const ProductImage = prod.image ? (
          <Image
            image={prod.image}
            maxWidth={350}
            maxHeight={200}
            layout="intrinsic"
          />
        ) : null

        const cta = !prod.mandatory ? (
          <Button
            text={selected ? translations.remove : translations.add}
            variation={selected ? 'secondary' : 'primary'}
            fullWidth
            ariaLabel={ctaAriaLabel}
            onClick={() => handler(prod.identifier, prod.name || prod.productName)}
          />
        ) : null

        return (
          <ProductSelectionCard
            key={prod.identifier}
            id={prod.identifier}
            title={prod.productName}
            byline={prod.byline}
            motif={prod.motif}
            image={ProductImage}
            price={price || null}
            details={documentToReactComponents(prod.details)}
            keyFeatures={prod.keyFeatures}
            contentSummary={prod.contentSummary}
            options={options || null}
            optionsLabel={prod.optionsLabel || null}
            onOptionChange={({ value }) => updateHandler(value, prod.identifier)}
            selected={selected}
            hint={hint || null}
            hintIcon={hintIcon || null}
            cta={cta}
          />
        )
      })}
    </Grid>
  )
}

export default ProductSelectionGrid
