import styled from 'styled-components'
import Questions from './Questions'
import Conditions from '../preview/conditions'
import ConditionalTextBlock from '../preview/conditional-text-block'

const Container = styled.div`
  border-bottom: 2px solid black;

  &:last-child {
    border-bottom: 0 none;
  }
`

const Question = ({ question }) => {
  const Component = Questions[question.component] || null

  return (
    <Container className="ml-base mt-md">
      {question.conditions && (
        <div className="mb-base">
          <Conditions conditions={question.conditions} />
        </div>
      )}
      <h3 className="mb-xs">{question.title}</h3>
      <pre className="m-zero body-sm semi-bold mb-base">[{question.key}]</pre>

      {question.infoTop && <ConditionalTextBlock content={question.infoTop} />}
      {question.additionalInfoTop && question.additionalInfoTop.map((info) => (
        <ConditionalTextBlock key={info.id} content={info.content} conditions={info.conditions} />
      ))}

      {Component && (
        <div className="mt-md mb-md">
          <Component question={question} />
        </div>
      )}

      {question.additionalInfoBottom && question.additionalInfoBottom.map((info) => (
        <ConditionalTextBlock key={info.id} content={info.content} conditions={info.conditions} />
      ))}
    </Container>
  )
}

export default Question
