import styled from 'styled-components'

const Row = styled.div`
  display: flex;
  align-items: center;
`

const Marker = styled.span`
  flex-grow: 0;
  flex-shrink: 0;
  height: 20px;
  width: 20px;
  border: 2px solid #313738;
  display: block;
  border-radius: ${({ isRounded }) => (isRounded ? '50%' : '0')};
`

const Pre = styled.pre`
  display: inline;
`

const List = ({ question }) => {
  if (!question?.options) return null

  return question.options.map((option) => (
    <Row key={option.value} className="mb-sm">
      <Marker className="mr-base" isRounded={question.component === 'radio'} />
      <span>{option.text} [<Pre className="body-sm m-zero">{option.value}</Pre>]</span>
    </Row>
  ))
}

export default List
