import { useEffect } from 'react'
import { BloodPressureField, OrderJourneyContent } from '@sh24/ui-components'
import { useAppContext } from '../../contexts/app-context'
import getQuestionInfo from '../../utils/get-question-info'

const BloodPressure = ({
  question,
  contextKey,
  context,
  dispatch,
}) => {
  const { appContext } = useAppContext()
  const { translations } = appContext
  const bp = context[contextKey]
  const errors = context?.errors || {}
  const { infoTop, infoBottom } = getQuestionInfo(context, question)

  useEffect(() => {
    if (!bp) {
      dispatch(contextKey, {
        systolic: '',
        diastolic: '',
      })
    }
  }, [])

  const handler = (e) => {
    dispatch(contextKey, {
      ...bp,
      [e.target.id]: e.target.value,
    })
  }

  const labels = {
    systolic: translations['field.systolic'],
    diastolic: translations['field.diastolic'],
  }

  return (
    <OrderJourneyContent>
      <BloodPressureField
        id={contextKey}
        labels={labels}
        title={question.title}
        bloodPressureReading={bp}
        infoTop={infoTop}
        infoBottom={infoBottom}
        errors={errors}
        onChange={handler}
      />
    </OrderJourneyContent>
  )
}

export default BloodPressure
