import { useEffect } from 'react'
import { OrderJourneyContent, WeightField } from '@sh24/ui-components'
import { useAppContext } from '../../contexts/app-context'
import { kgToStonePounds, stonePoundsToKg } from '../../utils/units-converters'
import getQuestionInfo from '../../utils/get-question-info'

const WeightQuestion = ({
  question,
  contextKey,
  context,
  dispatch,
}) => {
  const defaultUnit = 'metric'
  const { appContext } = useAppContext()
  const { translations } = appContext
  const weight = context[contextKey] || {}
  const errors = context?.errors || {}
  const { infoTop, infoBottom } = getQuestionInfo(context, question)

  useEffect(() => {
    if (!weight?.unit) {
      dispatch(contextKey, {
        unit: defaultUnit,
      })
    }
  }, [])

  const labels = {
    metricOption: translations['field.metricWeightOption'],
    imperialOption: translations['field.imperialWeightOption'],
    kilograms: translations['field.kilograms'],
    stone: translations['field.stone'],
    pounds: translations['field.pounds'],
  }

  const unitConverters = {
    imperial: stonePoundsToKg,
    metric: kgToStonePounds,
  }

  const handler = (e) => {
    const unitUpdate = ['imperial', 'metric'].includes(e.target.id)
    const key = unitUpdate ? 'unit' : e.target.id

    const updatedValues = {
      ...weight,
      [key]: e.target.value,
    }

    const convertedValues = unitUpdate ? {} : unitConverters[updatedValues.unit](updatedValues)

    dispatch(contextKey, {
      ...updatedValues,
      ...convertedValues,
    })
  }

  if (!weight?.unit) return null

  return (
    <OrderJourneyContent>
      <WeightField
        id={question.id}
        labels={labels}
        title={question.title}
        infoTop={infoTop}
        infoBottom={infoBottom}
        errors={errors}
        onChange={handler}
        weight={weight}
      />
    </OrderJourneyContent>
  )
}

export default WeightQuestion
