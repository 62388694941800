import styled from 'styled-components'

const Box = styled.div`
  ${({ isLarge }) => `
    background-color: #f6f6f6;
    border: 1px solid #313738;
    max-width: 100%;
    height: ${isLarge ? '100px' : '40px'};
    width: ${isLarge ? '500px' : '300px'};
  `}
`

const BasicInput = ({ question }) => (
  <>
    {question?.label && (<strong>{question.label}</strong>)}
    <Box isLarge={question?.component === 'textarea'} />
  </>
)

export default BasicInput
