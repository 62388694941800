import styled, { createGlobalStyle } from 'styled-components'
import { useState } from 'react'
import {
  Label,
  FlexRow,
  Button,
  FieldError,
} from '@sh24/ui-components'
import {
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js'
import { handleCardPayment } from '../../services/payments'
import useThemeContext from '../../utils/use-theme-context'
import useTranslations from '../../utils/use-translations'
import airbrake from '../../services/exception-notifier/client'

const SmallCardInputContainer = styled.div`
  max-width: ${({ theme }) => theme?.components?.forms?.input?.maxWidth};
`

const SmallCardInputWrapper = styled.div`
  width: calc(50% - ${({ theme }) => theme?.spacing?.sm});
`

const buildCardInputStyles = (theme) => ({
  base: {
    color: theme?.components?.forms?.input?.color?.base,
    fontSize: '16px',
    lineHeight: '38px',
  },
  invalid: {
    color: theme?.components?.forms?.input?.color?.base,
  },
})

const CardInputWrapperStyles = createGlobalStyle`
  ${({ theme }) => `
    .payment-input {
      border-bottom: 2px solid ${theme?.components?.forms?.input?.border?.color.base};
      border-radius: ${theme?.components?.forms?.input?.border?.radius};
      margin-bottom: ${theme?.spacing?.md};
      padding: 0px 20px;
      background-color: ${theme?.components?.forms?.input?.bgColor?.base};
    }

    .payment-input.StripeElement--complete {
      background-color: ${theme?.components?.forms?.input?.bgColor?.active};
    }

    .payment-input.StripeElement--focus {
      background-color: ${theme?.components?.forms?.input?.bgColor?.active};
      outline: 2px solid Highlight;
      outline: 2px solid -webkit-focus-ring-color;
    }

    .payment-input.StripeElement--invalid {
      border-color: ${theme?.components?.forms?.input?.border?.color?.invalid};
    }
  `}
`

const StripeForm = ({
  address,
  dispatch,
  nextPage,
  paymentIntent,
}) => {
  const [succeeded, setSucceeded] = useState(false)
  const [error, setError] = useState(null)
  const [processing, setProcessing] = useState(false)
  const theme = useThemeContext()
  const translations = useTranslations()
  const stripe = useStripe()
  const elements = useElements()

  const {
    addressLine1,
    addressLine2,
    city,
    postcode,
  } = address

  const formattedAddress = {
    line1: addressLine1,
    line2: addressLine2,
    city,
    postal_code: postcode,
  }

  const onSuccess = (payload) => {
    dispatch('paymentIntent', { ...payload, paymentMethod: 'card' })
    setProcessing(false)
    setSucceeded(true)
    nextPage()
  }

  const onError = (payload) => {
    setError(payload.error.message)
    setProcessing(false)
  }

  const handlePayment = async (e) => {
    e.preventDefault()
    setError(null)
    setProcessing(true)

    try {
      const success = await handleCardPayment({
        stripe,
        paymentIntent,
        paymentMethod: {
          card: elements.getElement(CardNumberElement),
          billing_details: {
            address: formattedAddress,
          },
        },
      })

      if (success.status === 'requires_capture') {
        onSuccess(success)
      } else {
        const errorPayload = {
          ...success,
          error: {
            message: 'We are unable to process your payment. Please try again or use a different payment method.',
          },
        }
        await airbrake.notify(errorPayload)
        onError(errorPayload)
      }
    } catch (errorPayload) {
      onError(errorPayload)
    }
  }

  const cardInputStyles = buildCardInputStyles(theme)

  return (
    <form onSubmit={handlePayment} className="mb-md">
      <CardInputWrapperStyles />
      <div>
        <Label>{translations['orderJourney.payment.cardNumber']}</Label>
        <CardNumberElement options={{ style: cardInputStyles, placeholder: '' }} className="payment-input" aria-describedby="KWEH" />
      </div>

      <SmallCardInputContainer>
        <FlexRow spaced>
          <SmallCardInputWrapper>
            <Label>{translations['orderJourney.payment.cardExpiryDate']}</Label>
            <CardExpiryElement options={{ style: cardInputStyles, placeholder: '' }} className="payment-input" />
          </SmallCardInputWrapper>

          <SmallCardInputWrapper>
            <Label>{translations['orderJourney.payment.cardCVV']}</Label>
            <CardCvcElement options={{ style: cardInputStyles, placeholder: '' }} className="payment-input" />
          </SmallCardInputWrapper>
        </FlexRow>
      </SmallCardInputContainer>

      <div className="mb-md" aria-live="polite">
        {error && <FieldError>{error}</FieldError>}
      </div>

      <Button
        id="submit"
        type="submit"
        disabled={processing || succeeded}
        loading={processing}
        onClick={handlePayment}
        text={processing ? translations['orderJourney.payment.processing'] : translations['orderJourney.placeOrder']}
        iconName="arrow-right"
        fullWidthOnMobile
      />
    </form>
  )
}

export default StripeForm
