import { useEffect } from 'react'
import PhoneNumberQuestion from './phone-number-question'

const PartnerPhoneNumbers = ({
  question,
  contextKey,
  context,
  dispatch,
}) => {
  const { noOfContactForPartners } = context
  const maxInputs = parseInt(noOfContactForPartners, 10)
  const inputs = [...Array(maxInputs).keys()]
  const parentId = contextKey

  const updateSubContext = (id, data) => {
    const newCtx = {
      ...context[parentId],
      [id]: data,
    }

    if (newCtx[id].input === '') delete newCtx[id]

    dispatch(parentId, newCtx)
  }

  useEffect(() => {
    // on entry clean up any redundant entries in the context
    if (context[parentId]) {
      const activeEntries = Object.entries(context[parentId])
        .filter(([key]) => parseInt(key, 10) <= maxInputs)

      dispatch(parentId, Object.fromEntries(activeEntries))
    }
  }, [])

  return inputs.map((input) => {
    const id = `${input + 1}`

    const subQuestion = {
      id,
      title: input === 0 ? question.title : null,
      infoTop: input === 0 ? question.infoTop : null,
      label: question.label,
    }

    const errors = context.errors?.[`${parentId}.${id}`] || []

    if (input === 0 && context.errors?.[parentId]) {
      errors.push(...context.errors[parentId])
    }

    const subContext = {
      [id]: context[parentId]?.[id] || null,
      errors: {
        [id]: errors,
      },
      phone_regions: context.phone_regions,
    }

    return (
      <PhoneNumberQuestion
        key={id}
        question={subQuestion}
        contextKey={id}
        context={subContext}
        dispatch={updateSubContext}
        saveRedacted={false}
      />
    )
  })
}

export default PartnerPhoneNumbers
