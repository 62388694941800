import conditionalTextBlockEntriesToRC from './conditional-text-block-entries-to-react-components'
import documentToReactComponents from './document-to-react-components'
import RichTextDocumentContext from '../contexts/rich-text-document-context'

const contextProvider = (ctx, info) => {
  if (!info.length) return null

  return (
    <RichTextDocumentContext.Provider value={ctx}>
      {info}
    </RichTextDocumentContext.Provider>
  )
}

const getQuestionInfo = (ctx, question) => {
  const {
    infoTop,
    additionalInfoTop,
    additionalInfoBottom,
  } = question
  const validAdditionalInfoTop = conditionalTextBlockEntriesToRC((additionalInfoTop ?? []), ctx)
  const validAdditionalInfoBtm = conditionalTextBlockEntriesToRC((additionalInfoBottom ?? []), ctx)
  const topInfos = [
    ...(infoTop ? documentToReactComponents(infoTop) : []),
    ...validAdditionalInfoTop,
  ]

  return {
    infoTop: contextProvider(ctx, topInfos),
    infoBottom: contextProvider(ctx, validAdditionalInfoBtm),
  }
}

export default getQuestionInfo
