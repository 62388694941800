const countryList = {
  AFG: {
    text: 'Afghanistan',
    value: 'AFG',
  },
  ALA: {
    text: 'Åland Islands',
    value: 'ALA',
  },
  ALB: {
    text: 'Albania',
    value: 'ALB',
  },
  DZA: {
    text: 'Algeria',
    value: 'DZA',
  },
  ASM: {
    text: 'American Samoa',
    value: 'ASM',
  },
  AND: {
    text: 'Andorra',
    value: 'AND',
  },
  AGO: {
    text: 'Angola',
    value: 'AGO',
  },
  AIA: {
    text: 'Anguilla',
    value: 'AIA',
  },
  ATA: {
    text: 'Antarctica',
    value: 'ATA',
  },
  ATG: {
    text: 'Antigua and Barbuda',
    value: 'ATG',
  },
  ARG: {
    text: 'Argentina',
    value: 'ARG',
  },
  ARM: {
    text: 'Armenia',
    value: 'ARM',
  },
  ABW: {
    text: 'Aruba',
    value: 'ABW',
  },
  AUS: {
    text: 'Australia',
    value: 'AUS',
  },
  AUT: {
    text: 'Austria',
    value: 'AUT',
  },
  AZE: {
    text: 'Azerbaijan',
    value: 'AZE',
  },
  BHS: {
    text: 'Bahamas',
    value: 'BHS',
  },
  BHR: {
    text: 'Bahrain',
    value: 'BHR',
  },
  BGD: {
    text: 'Bangladesh',
    value: 'BGD',
  },
  BRB: {
    text: 'Barbados',
    value: 'BRB',
  },
  BLR: {
    text: 'Belarus',
    value: 'BLR',
  },
  BEL: {
    text: 'Belgium',
    value: 'BEL',
  },
  BLZ: {
    text: 'Belize',
    value: 'BLZ',
  },
  BEN: {
    text: 'Benin',
    value: 'BEN',
  },
  BMU: {
    text: 'Bermuda',
    value: 'BMU',
  },
  BTN: {
    text: 'Bhutan',
    value: 'BTN',
  },
  BOL: {
    text: 'Bolivia',
    value: 'BOL',
  },
  BES: {
    text: 'Bonaire, Sint Eustatius and Saba',
    value: 'BES',
  },
  BIH: {
    text: 'Bosnia and Herzegovina',
    value: 'BIH',
  },
  BWA: {
    text: 'Botswana',
    value: 'BWA',
  },
  BVT: {
    text: 'Bouvet Island',
    value: 'BVT',
  },
  BRA: {
    text: 'Brazil',
    value: 'BRA',
  },
  IOT: {
    text: 'British Indian Ocean Territory',
    value: 'IOT',
  },
  BRN: {
    text: 'Brunei Darussalam',
    value: 'BRN',
  },
  BGR: {
    text: 'Bulgaria',
    value: 'BGR',
  },
  BFA: {
    text: 'Burkina Faso',
    value: 'BFA',
  },
  BDI: {
    text: 'Burundi',
    value: 'BDI',
  },
  CPV: {
    text: 'Cabo Verde',
    value: 'CPV',
  },
  KHM: {
    text: 'Cambodia',
    value: 'KHM',
  },
  CMR: {
    text: 'Cameroon',
    value: 'CMR',
  },
  CAN: {
    text: 'Canada',
    value: 'CAN',
  },
  CYM: {
    text: 'Cayman Islands',
    value: 'CYM',
  },
  CAF: {
    text: 'Central African Republic',
    value: 'CAF',
  },
  TCD: {
    text: 'Chad',
    value: 'TCD',
  },
  CHL: {
    text: 'Chile',
    value: 'CHL',
  },
  CHN: {
    text: 'China',
    value: 'CHN',
  },
  CXR: {
    text: 'Christmas Island',
    value: 'CXR',
  },
  CCK: {
    text: 'Cocos (Keeling) Islands',
    value: 'CCK',
  },
  COL: {
    text: 'Colombia',
    value: 'COL',
  },
  COM: {
    text: 'Comoros',
    value: 'COM',
  },
  COG: {
    text: 'Congo',
    value: 'COG',
  },
  COD: {
    text: 'Congo, The Democratic Republic of the',
    value: 'COD',
  },
  COK: {
    text: 'Cook Islands',
    value: 'COK',
  },
  CRI: {
    text: 'Costa Rica',
    value: 'CRI',
  },
  HRV: {
    text: 'Croatia',
    value: 'HRV',
  },
  CUB: {
    text: 'Cuba',
    value: 'CUB',
  },
  CUW: {
    text: 'Curaçao',
    value: 'CUW',
  },
  CYP: {
    text: 'Cyprus',
    value: 'CYP',
  },
  CZE: {
    text: 'Czechia',
    value: 'CZE',
  },
  CIV: {
    text: "Côte d'Ivoire",
    value: 'CIV',
  },
  DNK: {
    text: 'Denmark',
    value: 'DNK',
  },
  DJI: {
    text: 'Djibouti',
    value: 'DJI',
  },
  DMA: {
    text: 'Dominica',
    value: 'DMA',
  },
  DOM: {
    text: 'Dominican Republic',
    value: 'DOM',
  },
  ECU: {
    text: 'Ecuador',
    value: 'ECU',
  },
  EGY: {
    text: 'Egypt',
    value: 'EGY',
  },
  SLV: {
    text: 'El Salvador',
    value: 'SLV',
  },
  GNQ: {
    text: 'Equatorial Guinea',
    value: 'GNQ',
  },
  ERI: {
    text: 'Eritrea',
    value: 'ERI',
  },
  EST: {
    text: 'Estonia',
    value: 'EST',
  },
  SWZ: {
    text: 'Eswatini',
    value: 'SWZ',
  },
  ETH: {
    text: 'Ethiopia',
    value: 'ETH',
  },
  FLK: {
    text: 'Falkland Islands (Malvinas)',
    value: 'FLK',
  },
  FRO: {
    text: 'Faroe Islands',
    value: 'FRO',
  },
  FJI: {
    text: 'Fiji',
    value: 'FJI',
  },
  FIN: {
    text: 'Finland',
    value: 'FIN',
  },
  FRA: {
    text: 'France',
    value: 'FRA',
  },
  GUF: {
    text: 'French Guiana',
    value: 'GUF',
  },
  PYF: {
    text: 'French Polynesia',
    value: 'PYF',
  },
  ATF: {
    text: 'French Southern Territories',
    value: 'ATF',
  },
  GAB: {
    text: 'Gabon',
    value: 'GAB',
  },
  GMB: {
    text: 'Gambia',
    value: 'GMB',
  },
  GEO: {
    text: 'Georgia',
    value: 'GEO',
  },
  DEU: {
    text: 'Germany',
    value: 'DEU',
  },
  GHA: {
    text: 'Ghana',
    value: 'GHA',
  },
  GIB: {
    text: 'Gibraltar',
    value: 'GIB',
  },
  GRC: {
    text: 'Greece',
    value: 'GRC',
  },
  GRL: {
    text: 'Greenland',
    value: 'GRL',
  },
  GRD: {
    text: 'Grenada',
    value: 'GRD',
  },
  GLP: {
    text: 'Guadeloupe',
    value: 'GLP',
  },
  GUM: {
    text: 'Guam',
    value: 'GUM',
  },
  GTM: {
    text: 'Guatemala',
    value: 'GTM',
  },
  GGY: {
    text: 'Guernsey',
    value: 'GGY',
  },
  GIN: {
    text: 'Guinea',
    value: 'GIN',
  },
  GNB: {
    text: 'Guinea-Bissau',
    value: 'GNB',
  },
  GUY: {
    text: 'Guyana',
    value: 'GUY',
  },
  HTI: {
    text: 'Haiti',
    value: 'HTI',
  },
  HMD: {
    text: 'Heard Island and McDonald Islands',
    value: 'HMD',
  },
  VAT: {
    text: 'Holy See (Vatican City State)',
    value: 'VAT',
  },
  HND: {
    text: 'Honduras',
    value: 'HND',
  },
  HKG: {
    text: 'Hong Kong',
    value: 'HKG',
  },
  HUN: {
    text: 'Hungary',
    value: 'HUN',
  },
  ISL: {
    text: 'Iceland',
    value: 'ISL',
  },
  IND: {
    text: 'India',
    value: 'IND',
  },
  IDN: {
    text: 'Indonesia',
    value: 'IDN',
  },
  IRN: {
    text: 'Iran',
    value: 'IRN',
  },
  IRQ: {
    text: 'Iraq',
    value: 'IRQ',
  },
  IRL: {
    text: 'Ireland',
    value: 'IRL',
  },
  IMN: {
    text: 'Isle of Man',
    value: 'IMN',
  },
  ISR: {
    text: 'Israel',
    value: 'ISR',
  },
  ITA: {
    text: 'Italy',
    value: 'ITA',
  },
  JAM: {
    text: 'Jamaica',
    value: 'JAM',
  },
  JPN: {
    text: 'Japan',
    value: 'JPN',
  },
  JEY: {
    text: 'Jersey',
    value: 'JEY',
  },
  JOR: {
    text: 'Jordan',
    value: 'JOR',
  },
  KAZ: {
    text: 'Kazakhstan',
    value: 'KAZ',
  },
  KEN: {
    text: 'Kenya',
    value: 'KEN',
  },
  KIR: {
    text: 'Kiribati',
    value: 'KIR',
  },
  KWT: {
    text: 'Kuwait',
    value: 'KWT',
  },
  KGZ: {
    text: 'Kyrgyzstan',
    value: 'KGZ',
  },
  LAO: {
    text: "Lao People's Democratic Republic",
    value: 'LAO',
  },
  LVA: {
    text: 'Latvia',
    value: 'LVA',
  },
  LBN: {
    text: 'Lebanon',
    value: 'LBN',
  },
  LSO: {
    text: 'Lesotho',
    value: 'LSO',
  },
  LBR: {
    text: 'Liberia',
    value: 'LBR',
  },
  LBY: {
    text: 'Libya',
    value: 'LBY',
  },
  LIE: {
    text: 'Liechtenstein',
    value: 'LIE',
  },
  LTU: {
    text: 'Lithuania',
    value: 'LTU',
  },
  LUX: {
    text: 'Luxembourg',
    value: 'LUX',
  },
  MAC: {
    text: 'Macao',
    value: 'MAC',
  },
  MDG: {
    text: 'Madagascar',
    value: 'MDG',
  },
  MWI: {
    text: 'Malawi',
    value: 'MWI',
  },
  MYS: {
    text: 'Malaysia',
    value: 'MYS',
  },
  MDV: {
    text: 'Maldives',
    value: 'MDV',
  },
  MLI: {
    text: 'Mali',
    value: 'MLI',
  },
  MLT: {
    text: 'Malta',
    value: 'MLT',
  },
  MHL: {
    text: 'Marshall Islands',
    value: 'MHL',
  },
  MTQ: {
    text: 'Martinique',
    value: 'MTQ',
  },
  MRT: {
    text: 'Mauritania',
    value: 'MRT',
  },
  MUS: {
    text: 'Mauritius',
    value: 'MUS',
  },
  MYT: {
    text: 'Mayotte',
    value: 'MYT',
  },
  MEX: {
    text: 'Mexico',
    value: 'MEX',
  },
  FSM: {
    text: 'Micronesia, Federated States of',
    value: 'FSM',
  },
  MDA: {
    text: 'Moldova',
    value: 'MDA',
  },
  MCO: {
    text: 'Monaco',
    value: 'MCO',
  },
  MNG: {
    text: 'Mongolia',
    value: 'MNG',
  },
  MNE: {
    text: 'Montenegro',
    value: 'MNE',
  },
  MSR: {
    text: 'Montserrat',
    value: 'MSR',
  },
  MAR: {
    text: 'Morocco',
    value: 'MAR',
  },
  MOZ: {
    text: 'Mozambique',
    value: 'MOZ',
  },
  MMR: {
    text: 'Myanmar',
    value: 'MMR',
  },
  NAM: {
    text: 'Namibia',
    value: 'NAM',
  },
  NRU: {
    text: 'Nauru',
    value: 'NRU',
  },
  NPL: {
    text: 'Nepal',
    value: 'NPL',
  },
  NLD: {
    text: 'Netherlands',
    value: 'NLD',
  },
  NCL: {
    text: 'New Caledonia',
    value: 'NCL',
  },
  NZL: {
    text: 'New Zealand',
    value: 'NZL',
  },
  NIC: {
    text: 'Nicaragua',
    value: 'NIC',
  },
  NER: {
    text: 'Niger',
    value: 'NER',
  },
  NGA: {
    text: 'Nigeria',
    value: 'NGA',
  },
  NIU: {
    text: 'Niue',
    value: 'NIU',
  },
  NFK: {
    text: 'Norfolk Island',
    value: 'NFK',
  },
  PRK: {
    text: 'North Korea',
    value: 'PRK',
  },
  MKD: {
    text: 'North Macedonia',
    value: 'MKD',
  },
  MNP: {
    text: 'Northern Mariana Islands',
    value: 'MNP',
  },
  NOR: {
    text: 'Norway',
    value: 'NOR',
  },
  OMN: {
    text: 'Oman',
    value: 'OMN',
  },
  PAK: {
    text: 'Pakistan',
    value: 'PAK',
  },
  PLW: {
    text: 'Palau',
    value: 'PLW',
  },
  PSE: {
    text: 'Palestine, State of',
    value: 'PSE',
  },
  PAN: {
    text: 'Panama',
    value: 'PAN',
  },
  PNG: {
    text: 'Papua New Guinea',
    value: 'PNG',
  },
  PRY: {
    text: 'Paraguay',
    value: 'PRY',
  },
  PER: {
    text: 'Peru',
    value: 'PER',
  },
  PHL: {
    text: 'Philippines',
    value: 'PHL',
  },
  PCN: {
    text: 'Pitcairn',
    value: 'PCN',
  },
  POL: {
    text: 'Poland',
    value: 'POL',
  },
  PRT: {
    text: 'Portugal',
    value: 'PRT',
  },
  PRI: {
    text: 'Puerto Rico',
    value: 'PRI',
  },
  QAT: {
    text: 'Qatar',
    value: 'QAT',
  },
  ROU: {
    text: 'Romania',
    value: 'ROU',
  },
  RUS: {
    text: 'Russian Federation',
    value: 'RUS',
  },
  RWA: {
    text: 'Rwanda',
    value: 'RWA',
  },
  REU: {
    text: 'Réunion',
    value: 'REU',
  },
  BLM: {
    text: 'Saint Barthélemy',
    value: 'BLM',
  },
  SHN: {
    text: 'Saint Helena, Ascension and Tristan da Cunha',
    value: 'SHN',
  },
  KNA: {
    text: 'Saint Kitts and Nevis',
    value: 'KNA',
  },
  LCA: {
    text: 'Saint Lucia',
    value: 'LCA',
  },
  MAF: {
    text: 'Saint Martin (French part)',
    value: 'MAF',
  },
  SPM: {
    text: 'Saint Pierre and Miquelon',
    value: 'SPM',
  },
  VCT: {
    text: 'Saint Vincent and the Grenadines',
    value: 'VCT',
  },
  WSM: {
    text: 'Samoa',
    value: 'WSM',
  },
  SMR: {
    text: 'San Marino',
    value: 'SMR',
  },
  STP: {
    text: 'Sao Tome and Principe',
    value: 'STP',
  },
  SAU: {
    text: 'Saudi Arabia',
    value: 'SAU',
  },
  SEN: {
    text: 'Senegal',
    value: 'SEN',
  },
  SRB: {
    text: 'Serbia',
    value: 'SRB',
  },
  SYC: {
    text: 'Seychelles',
    value: 'SYC',
  },
  SLE: {
    text: 'Sierra Leone',
    value: 'SLE',
  },
  SGP: {
    text: 'Singapore',
    value: 'SGP',
  },
  SXM: {
    text: 'Sint Maarten (Dutch part)',
    value: 'SXM',
  },
  SVK: {
    text: 'Slovakia',
    value: 'SVK',
  },
  SVN: {
    text: 'Slovenia',
    value: 'SVN',
  },
  SLB: {
    text: 'Solomon Islands',
    value: 'SLB',
  },
  SOM: {
    text: 'Somalia',
    value: 'SOM',
  },
  ZAF: {
    text: 'South Africa',
    value: 'ZAF',
  },
  SGS: {
    text: 'South Georgia and the South Sandwich Islands',
    value: 'SGS',
  },
  KOR: {
    text: 'South Korea',
    value: 'KOR',
  },
  SSD: {
    text: 'South Sudan',
    value: 'SSD',
  },
  ESP: {
    text: 'Spain',
    value: 'ESP',
  },
  LKA: {
    text: 'Sri Lanka',
    value: 'LKA',
  },
  SDN: {
    text: 'Sudan',
    value: 'SDN',
  },
  SUR: {
    text: 'Suriname',
    value: 'SUR',
  },
  SJM: {
    text: 'Svalbard and Jan Mayen',
    value: 'SJM',
  },
  SWE: {
    text: 'Sweden',
    value: 'SWE',
  },
  CHE: {
    text: 'Switzerland',
    value: 'CHE',
  },
  SYR: {
    text: 'Syrian Arab Republic',
    value: 'SYR',
  },
  TWN: {
    text: 'Taiwan',
    value: 'TWN',
  },
  TJK: {
    text: 'Tajikistan',
    value: 'TJK',
  },
  TZA: {
    text: 'Tanzania',
    value: 'TZA',
  },
  THA: {
    text: 'Thailand',
    value: 'THA',
  },
  TLS: {
    text: 'Timor-Leste',
    value: 'TLS',
  },
  TGO: {
    text: 'Togo',
    value: 'TGO',
  },
  TKL: {
    text: 'Tokelau',
    value: 'TKL',
  },
  TON: {
    text: 'Tonga',
    value: 'TON',
  },
  TTO: {
    text: 'Trinidad and Tobago',
    value: 'TTO',
  },
  TUN: {
    text: 'Tunisia',
    value: 'TUN',
  },
  TKM: {
    text: 'Turkmenistan',
    value: 'TKM',
  },
  TCA: {
    text: 'Turks and Caicos Islands',
    value: 'TCA',
  },
  TUV: {
    text: 'Tuvalu',
    value: 'TUV',
  },
  TUR: {
    text: 'Türkiye',
    value: 'TUR',
  },
  UGA: {
    text: 'Uganda',
    value: 'UGA',
  },
  UKR: {
    text: 'Ukraine',
    value: 'UKR',
  },
  ARE: {
    text: 'United Arab Emirates',
    value: 'ARE',
  },
  GBR: {
    text: 'United Kingdom',
    value: 'GBR',
  },
  USA: {
    text: 'United States',
    value: 'USA',
  },
  UMI: {
    text: 'United States Minor Outlying Islands',
    value: 'UMI',
  },
  URY: {
    text: 'Uruguay',
    value: 'URY',
  },
  UZB: {
    text: 'Uzbekistan',
    value: 'UZB',
  },
  VUT: {
    text: 'Vanuatu',
    value: 'VUT',
  },
  VEN: {
    text: 'Venezuela',
    value: 'VEN',
  },
  VNM: {
    text: 'Vietnam',
    value: 'VNM',
  },
  VGB: {
    text: 'Virgin Islands, British',
    value: 'VGB',
  },
  VIR: {
    text: 'Virgin Islands, U.S.',
    value: 'VIR',
  },
  WLF: {
    text: 'Wallis and Futuna',
    value: 'WLF',
  },
  ESH: {
    text: 'Western Sahara',
    value: 'ESH',
  },
  YEM: {
    text: 'Yemen',
    value: 'YEM',
  },
  ZMB: {
    text: 'Zambia',
    value: 'ZMB',
  },
  ZWE: {
    text: 'Zimbabwe',
    value: 'ZWE',
  },
}

export default countryList
