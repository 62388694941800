import { OrderJourneyContent } from '@sh24/ui-components'
import Section from '../components/OrderJourneyPreview/section'
import Kickout from '../components/OrderJourneyPreview/kickout'
import Products from '../components/OrderJourneyPreview/products'

const OrderJourneyPreviewTemplate = ({ journey }) => {
  const steps = journey.steps.filter((step) => !step.service)
  const questionCount = steps.reduce((acc, step) => (acc + (step.questions?.length || 0)), 0)
  const products = journey.products.filter((prod) => !prod.isExtra)
  const extras = journey.products.filter((prod) => prod.isExtra)

  return (
    <OrderJourneyContent wide>
      <div>
        <div className="mt-lg mb-lg">
          <h3>Details</h3>
          <p className="mb-xs"><strong>Total steps:</strong> {steps.length}</p>
          <p><strong>Total questions:</strong> {questionCount}</p>
        </div>

        <div className="mb-lg">
          <h3>Available Products</h3>
          <Products products={products} />
        </div>

        {extras.length > 0 && (
          <div className="mb-lg">
            <h3>Available Extras</h3>
            <Products products={extras} />
          </div>
        )}

        <div className="mb-lg">
          <h3>Kickouts</h3>
          {journey.kickouts && journey.kickouts.map((kickout) => (
            <Kickout key={kickout.id} kickout={kickout} />
          ))}
        </div>

        <div className="mb-lg">
          <h3>Journey</h3>
          {journey.sections.map((section) => (
            <Section
              key={section.id}
              section={section}
              steps={journey.steps.filter((step) => step.sectionId === section.id)}
            />
          ),
          )}
        </div>
      </div>
    </OrderJourneyContent>
  )
}

export default OrderJourneyPreviewTemplate
