import {
  ErrorMessages,
  OrderJourneyContent,
  Decoration,
} from '@sh24/ui-components'
import styled from 'styled-components'
import getQuestionInfo from '../../utils/get-question-info'

const Top = styled.div`
  background-color: ${({ theme }) => theme?.palette?.white};
`

const Bottom = styled.div`
  margin-top: -50px;
`

const Section = ({
  context,
  contextKey,
  question,
}) => {
  const errors = context?.errors?.[contextKey] || []
  const { infoTop, infoBottom } = getQuestionInfo(context, question)

  return (
    <>
      <Top>
        <OrderJourneyContent>
          <div>
            <h1 className="heading heading-3">{question.title}</h1>
            {infoTop}
          </div>
        </OrderJourneyContent>
      </Top>
      {infoBottom && (
        <>
          <Decoration type="/curve/quarter/left" fill="white" />
          <Bottom className="pt-xl pb-md">
            <OrderJourneyContent>
              <div>
                {infoBottom}
              </div>
            </OrderJourneyContent>
          </Bottom>
        </>
      )}
      {errors?.length > 0 && (
        <OrderJourneyContent>
          <ErrorMessages errors={errors} />
        </OrderJourneyContent>
      )}
    </>
  )
}

export default Section
