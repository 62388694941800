import { PaymentOptions } from '@sh24/ui-components'
import { Elements } from '@stripe/react-stripe-js'
import shouldLoadStripe from '../../utils/should-load-stripe'
import usePaymentIntent from '../../hooks/use-payment-intent'
import StripeForm from './stripe-form'
import PaypalForm from './paypal-form'
import AppleGoogle from './apple-google-form'

const stripePublicKey = process.env.NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY
let stripePromise = null

if (shouldLoadStripe(process.env.NEXT_PUBLIC_SITE)) {
  import('@stripe/stripe-js').then(({ loadStripe }) => {
    stripePromise = loadStripe(
      stripePublicKey,
      {
        apiVersion: '2022-11-15',
      },
    )
  })
}

const Payment = ({
  title,
  info,
  amount,
  context,
  dispatch,
  nextPage,
  question,
}) => {
  if (!stripePromise) throw new Error('<Payment /> rendered without stripe')
  const {
    basket,
    orderTypeDescription,
  } = context
  const paymentAddress = context.billingAddressSame === 'yes'
    ? context.deliveryAddress
    : context.billingAddress
  const receiptEmail = context.confirmationEmail === 'yes'
    ? context.email
    : null

  const controller = new AbortController()
  const paymentIntent = usePaymentIntent({
    amount,
    basket,
    orderTypeDescription,
    receiptEmail,
    currency: 'GBP',
    controller,
  })

  const paymentOptionForms = {
    card: (
      <Elements stripe={stripePromise}>
        <StripeForm
          address={paymentAddress}
          dispatch={dispatch}
          nextPage={nextPage}
          paymentIntent={paymentIntent}
        />
      </Elements>
    ),
    paypal: (
      <PaypalForm amount={amount} nextPage={nextPage} />
    ),
    applepay: (
      <Elements stripe={stripePromise}>
        <AppleGoogle
          amount={amount}
          dispatch={dispatch}
          nextPage={nextPage}
          paymentIntent={paymentIntent}
        />
      </Elements>
    ),
  }

  const paymentOptionsWithForms = question?.options?.map((option) => ({
    ...option,
    form: paymentOptionForms[option.value],
  }))

  return (
    <div className="row mt-lg">
      <div className="col col-12 col-md-10 col-lg-9">
        <PaymentOptions
          title={title}
          info={info}
          options={paymentOptionsWithForms}
        />
      </div>
    </div>
  )
}

export default Payment
