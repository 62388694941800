import { SelectField, OrderJourneyContent } from '@sh24/ui-components'
import countryList from './country-list-map'
import homeCountries from './home-countries-map'
import useTranslations from '../../../utils/use-translations'
import getQuestionInfo from '../../../utils/get-question-info'

const sortedOptions = Object.values(countryList).sort((a, b) => a.text.localeCompare(b.text))

const CountriesDropdownQuestion = ({
  question,
  contextKey,
  context,
  dispatch,
}) => {
  const translations = useTranslations()
  const errors = context?.errors?.[contextKey] || []
  const selectedCountry = context[contextKey]
  const homeCountryOption = homeCountries[process.env.NEXT_PUBLIC_SITE] || []
  const selectedCountryOption = countryList[selectedCountry]
    ? {
      label: countryList[selectedCountry].text,
      value: countryList[selectedCountry].value,
    }
    : null
  const { infoTop, infoBottom } = getQuestionInfo(context, question)

  const onChange = (payload) => {
    dispatch(contextKey, payload?.value)
  }

  return (
    <OrderJourneyContent>
      <SelectField
        id={question.id}
        title={question.title}
        label={question.label}
        infoTop={infoTop}
        infoBottom={infoBottom}
        value={selectedCountryOption}
        options={[
          ...homeCountryOption,
          { text: translations.allCountries, value: sortedOptions },
        ]}
        onChange={onChange}
        showDropdown={question.showDropdown}
        errors={errors}
      />
    </OrderJourneyContent>
  )
}

export default CountriesDropdownQuestion
