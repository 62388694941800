export const initialisePaymentIntent = async ({
  payload,
  controller,
}) => {
  const res = await fetch('/api/payments', {
    signal: controller.signal,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
    body: JSON.stringify({
      description: payload.description,
      amount: payload.amount,
      currency: payload.currency,
      receiptEmail: payload.receiptEmail,
    }),
  })

  const json = await res.json()
  if (res.ok) return Promise.resolve(json)
  return Promise.reject(json)
}

export const initialisePaymentRequest = ({ stripe, currency, amount }) => stripe.paymentRequest({
  country: 'GB',
  currency: currency.toLowerCase(),
  total: {
    label: 'Total',
    amount,
  },
  requestPayerName: true,
  requestPayerEmail: true,
})

export const handleCardPayment = async ({
  stripe,
  paymentIntent,
  paymentMethod,
  options,
}) => {
  const payload = await stripe.confirmCardPayment(
    paymentIntent.client_secret,
    paymentMethod ? { payment_method: paymentMethod } : undefined,
    options,
  )

  if (payload.error) return Promise.reject(payload)
  return Promise.resolve(payload.paymentIntent)
}
