import React, { useEffect, useState } from 'react'
import { AddressField, OrderJourneyContent } from '@sh24/ui-components'
import useTranslations from '../../utils/use-translations'
import lookupTranslations from '../../utils/lookup-translations'
import countryForBrand from '../../utils/country-for-brand'
import getQuestionInfo from '../../utils/get-question-info'

export const ADDRESS_LIST_SUFFIX = 'addressList'

const findAddresses = async (query) => {
  const res = await fetch(`/api/find-address?q=${query}`)
  if (res) {
    const { data } = await res.json()
    return data
  }
  return []
}

const retrieveAddress = async (selectedAddress) => {
  const res = await fetch(`/api/retrieve-address?id=${selectedAddress?.value}`)
  if (res) {
    const { data } = await res.json()
    return data
  }
  return {}
}

const AddressLookupQuestion = ({
  question,
  contextKey,
  context,
  dispatch,
  withContainer = true,
}) => {
  const [searching, setSearching] = useState(true)
  const prefilled = question.component === 'addressLookupPrefilled'
  const translations = useTranslations()
  const { infoTop, infoBottom } = getQuestionInfo(context, question)
  const address = context[contextKey] || {}
  const addressQuery = prefilled
    ? context.postcode?.toUpperCase()
    : address?.addressQuery
  const selectedAddress = address?.selectedAddress
  const addressListCtxKey = `${question.id}_${ADDRESS_LIST_SUFFIX}`
  const addressList = context[addressListCtxKey]
  const errors = lookupTranslations({ translations: context?.errors || {}, lookup: `${contextKey}.` })
  const country = countryForBrand(process.env.NEXT_PUBLIC_SITE)
  const addressLookupPlaceholder = translations['addressLookup.placeholder'] || ''
  const addressSelectPlaceholder = translations['addressLookup.selectPlaceholder'] || ''
  const manualEntryButtonText = translations['button.manualAddressEntry']
  const findAddressButtonText = translations['button.findAddress']

  const postcodeTranslations = {
    IE: translations['field.eircode'],
    GB: translations['field.postcode'],
  }

  const labels = {
    addressLine1: translations['field.addressLine1'],
    addressLine2: translations['field.addressLine2'],
    city: translations['field.city'],
    postcode: postcodeTranslations[country],
  }

  const onSearchInputChange = (e) => {
    setSearching(true)
    dispatch(contextKey, {
      addressQuery: e.target.value,
    })
    dispatch(addressListCtxKey, null)
  }

  const onSelectAddress = async (selectedAddressObject) => {
    const newAddress = await retrieveAddress(selectedAddressObject)

    dispatch(contextKey, {
      ...newAddress,
      postcode: prefilled ? addressQuery : newAddress.postcode,
      addressQuery,
      selectedAddress: selectedAddressObject,
    })
  }

  const onFindAddress = async (e) => {
    e?.preventDefault()
    if (!searching) return
    if (!addressQuery || addressQuery.length < 3) {
      dispatch('errors', {
        [`${contextKey}.addressQuery`]: [translations['errors.addressQuery']],
      })
      return
    }

    const list = await findAddresses(addressQuery)

    dispatch(addressListCtxKey, list)
    dispatch(contextKey, {
      addressQuery,
      selectedAddress: null,
      ...(prefilled ? { postcode: addressQuery } : {}),
    })

    if (list.length === 1) {
      await onSelectAddress({
        label: list[0].text,
        value: list[0].value,
      })
    }

    setSearching(false)
  }

  const onAddressLineChange = (event) => {
    const { id, value } = event.target
    const key = id.replace(`${question.id}-`, '')

    dispatch(contextKey, {
      ...address,
      [key]: value,
    })
  }

  useEffect(async () => {
    if (prefilled && addressQuery !== address?.postcode) {
      await onFindAddress()
    } else {
      dispatch(contextKey, address)
    }
  }, [])

  const Container = withContainer ? OrderJourneyContent : React.Fragment

  return (
    <Container>
      <AddressField
        id={question.id}
        prefilled={prefilled}
        title={question?.title}
        infoTop={infoTop}
        infoBottom={infoBottom}
        manualEntryButtonText={manualEntryButtonText}
        findAddressButtonText={findAddressButtonText}
        label={question?.label}
        errors={errors}
        labels={labels}
        addressLookupPlaceholder={addressLookupPlaceholder}
        addressSelectPlaceholder={addressSelectPlaceholder}
        inputValue={addressQuery || ''}
        selectedAddress={selectedAddress}
        addressList={addressList}
        address={address}
        onChange={onAddressLineChange}
        onFindAddress={onFindAddress}
        onInputChange={onSearchInputChange}
        onSelectAddress={onSelectAddress}
        formatOptionLabel={(option, meta) => {
          const formats = {
            menu: option?.label,
            value: selectedAddress?.label,
          }
          return formats[meta?.context]
        }}
      />
    </Container>
  )
}

export default AddressLookupQuestion
