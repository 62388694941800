const UK_ETHNICITIES_OPTIONS = [
  {
    text: 'White',
    value: [
      { text: 'White English / Welsh / Scottish / Northern Irish / British', value: 'white_english_welsh_scottish_northern_irish_british' },
      { text: 'Irish', value: 'irish' },
      { text: 'Gypsy or Irish Traveller', value: 'gypsy_or_irish_traveller' },
      { text: 'Other White background', value: 'other_white' },
    ],
  },
  {
    text: 'Mixed / multiple ethnic groups',
    value: [
      { text: 'White and Black African', value: 'white_and_black_african' },
      { text: 'White and Black Caribbean', value: 'white_and_black_caribbean' },
      { text: 'White and Asian', value: 'white_and_asian' },
      { text: 'Other mixed / multiple ethnic groups', value: 'other_mixed_multiple_ethnic_groups' },
    ],
  },
  {
    text: 'Asian / Asian British',
    value: [
      { text: 'Indian', value: 'indian' },
      { text: 'Pakistani', value: 'pakistani' },
      { text: 'Bangladeshi', value: 'bangladeshi' },
      { text: 'Chinese', value: 'chinese' },
      { text: 'Other Asian / Asian British background', value: 'other_asian_asian_british' },
    ],
  },
  {
    text: 'Black / African / Caribbean / Black British',
    value: [
      { text: 'African', value: 'african' },
      { text: 'Caribbean', value: 'caribbean' },
      { text: 'Other Black / African / Caribbean / Black British background', value: 'other_black_african_caribbean_black_british' },
    ],
  },
  {
    text: 'Other ethnic group / background',
    value: [
      { text: 'Arab', value: 'arab' },
      { text: 'Latin American', value: 'latin_american' },
      { text: 'Other ethnic group', value: 'other_ethnic_group' },
    ],
  },
  {
    text: 'Other',
    value: [
      { text: 'Not Known', value: 'not_known' },
      { text: 'Prefer not to say', value: 'prefer_not_to_say' },
    ],
  },
]

const IRISH_ETHNICITIES_OPTIONS = [
  {
    text: 'White',
    value: [
      { text: 'Irish', value: 'irish' },
      { text: 'Irish Traveller', value: 'irish_traveller' },
      { text: 'Roma', value: 'roma' },
      { text: 'Other White background', value: 'other_white' },
    ],
  },
  {
    text: 'Mixed / multiple ethnic groups',
    value: [
      { text: 'White and Black African', value: 'white_and_black_african' },
      { text: 'White and Black Caribbean', value: 'white_and_black_caribbean' },
      { text: 'White and Asian', value: 'white_and_asian' },
      { text: 'Other mixed / multiple ethnic groups', value: 'other_mixed_multiple_ethnic_groups' },
    ],
  },
  {
    text: 'Asian / Asian Irish',
    value: [
      { text: 'Chinese', value: 'chinese' },
      { text: 'Indian / Pakistani / Bangladeshi', value: 'indian_pakistani_bangladeshi' },
      { text: 'Other Asian background', value: 'other_asian_background' },
    ],
  },
  {
    text: 'Black / Black Irish',
    value: [
      { text: 'African', value: 'african' },
      { text: 'Caribbean', value: 'caribbean' },
      { text: 'Other Black background', value: 'other_black_background' },
    ],
  },
  {
    text: 'Other ethnic group / background',
    value: [
      { text: 'Arab', value: 'arab' },
      { text: 'Latin American', value: 'latin_american' },
      { text: 'Other ethnic group', value: 'other_ethnic_group' },
    ],
  },
  {
    text: 'Other',
    value: [
      { text: 'Not Known', value: 'not_known' },
      { text: 'Prefer not to say', value: 'prefer_not_to_say' },
    ],
  },
]

export default {
  sh24: UK_ETHNICITIES_OPTIONS,
  hep_c_ireland: IRISH_ETHNICITIES_OPTIONS,
  hcrg: UK_ETHNICITIES_OPTIONS,
  sh24_ireland: IRISH_ETHNICITIES_OPTIONS,
}
