import {
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'
import { useSelector } from '@xstate/react'
import { useRouter } from 'next/router'
import Head from 'next/head'
import {
  Button,
  Icon,
  OrderJourneyContent,
  Page,
  ProgressBar,
} from '@sh24/ui-components'
import styled from 'styled-components'
import SEO from '../components/SEO/seo'
import { JourneyContext } from '../order-journeys/_v2/context/journey-context'
import DebugPanel from '../components/DebugPanel/debug-panel'
import {
  getActiveItems,
  getCurrentStep,
  getCurrentSection,
} from '../order-journeys/_v2/helpers'
import QuestionComponents from '../components/Questions'
import CobrandingLogo from '../components/CobrandingLogo/cobranding-logo'
import Basket from '../components/Basket/basket'
import useTranslations from '../utils/use-translations'

import {
  AccountSelectionModule,
  ContentModule,
  HighlightsModule,
  IntroductionModule,
  TimelineModule,
  HelpSnippetsModule,
} from '../components/Modules'

export const moduleMap = {
  content: ContentModule,
  helpSnippets: HelpSnippetsModule,
  highlights: HighlightsModule,
  introduction: IntroductionModule,
  timeline: TimelineModule,
  accountSelection: AccountSelectionModule,
}

const Centered = styled.div`
  text-align: center;
`

const Divide = styled.hr`
  ${({ theme }) => `
    margin-bottom: ${theme?.spacing?.md};
    margin-top: ${theme?.spacing?.sm};
    border-top: 1px solid ${theme?.components?.basket?.divideColor};
    border-bottom: 0 none;
  `}
`

const PullRight = styled.div`
  text-align: right;
`

const OrderForm = styled.form`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  ${({ theme, hasGreyBackground }) => `
    ${hasGreyBackground ? `background-color: ${theme?.palette?.tertiary200};` : ''}
  `}

  &:active, &:focus {
    outline: none;
  }
`

const PaymentWrapper = styled.div`
  background-color: ${({ theme }) => theme?.components?.basket?.bgColor};
  flex-grow: 1;
  display: flex;
  flex-direction: column;
`

const contextSelector = (state) => state.context
const isStepSelector = (state) => state.matches('step')

const Template = ({ showDebug }) => {
  const [customNextHandler, setCustomNextHandler] = useState()
  const [continueEnabled, setContinueEnabled] = useState(true)
  const [statusText, setStatusText] = useState('')
  const formRef = useRef(null)
  const router = useRouter()
  const translations = useTranslations()
  const service = useContext(JourneyContext)
  const { send } = service
  const isStep = useSelector(service, isStepSelector)
  const ctx = useSelector(service, contextSelector)
  const {
    ready,
    basket,
    totals,
    currentStep,
    journey,
    co_branding_logo: cobrandingLogo,
    co_branding_alt_text: cobrandingAlt,
  } = ctx

  useEffect(() => {
    send('HYDRATE')
  }, [service])

  useEffect(() => {
    window.scrollTo(0, 0)
    setContinueEnabled(true)
  }, [currentStep])

  useEffect(() => {
    if (router.isReady) {
      send('UPDATE_URL')
    }
  }, [router.isReady, currentStep])

  useEffect(() => {
    if (router.isReady && isStep && currentStep === 0) {
      send('SAVE_PARAMS', { query: router.query })
    }
  }, [router.query, isStep])

  if (!ready || !journey) return null

  const hasBasketItems = basket?.length > 0
  const step = getCurrentStep(ctx)
  const isPaymentStep = isStep && (step.id === 'payment' || step.id === 'checkout')
  const questions = getActiveItems(step?.questions, ctx)
  const sections = getActiveItems(journey.sections, ctx)
  const sectionNames = sections.map((section) => section.title)
  const { title: currentSectionTitle } = getCurrentSection(ctx) || {}
  const showContinueButton = !isPaymentStep && !hasBasketItems
  const showPlaceOrderButton = isPaymentStep && totals?.grandTotal?.amount === 0
  const showBasket = !isPaymentStep && hasBasketItems
  const continueText = step?.continueButtonText || translations['orderJourney.continue']

  const nextPage = (e) => {
    e?.preventDefault()
    send('NEXT')
    formRef?.current?.focus()
  }

  const nextHandler = customNextHandler || nextPage

  const previousHandler = (e) => {
    e.preventDefault()
    send('BACK')
  }

  const updateContext = (key, value) => {
    send('UPDATE', {
      key,
      value,
    })
  }

  const [topModule, ...bottomModules] = step?.modules || []
  const TopModuleComponent = moduleMap[topModule?.contentType] || null

  const isFancySection = questions.some((question) => (
    question.component === 'section'
    && question.additionalInfoBottom?.length > 0
  ))

  const hasBottomModules = bottomModules.length > 0

  const hasGreyBackground = isFancySection || hasBottomModules

  const Wrapper = isPaymentStep ? PaymentWrapper : OrderForm
  const wrapperProps = isPaymentStep
    ? {}
    : {
      ref: formRef,
      onSubmit: nextHandler,
      tabIndex: 0,
      hasGreyBackground,
    }

  const loadingView = (
    <OrderJourneyContent wide>
      <Centered>
        <Icon name="loading" height={36} width={36} animation="spin" />
        <h3 className="mt-md">{translations['orderJourney.loading']}</h3>
      </Centered>
    </OrderJourneyContent>
  )

  const defaultView = (
    <>
      {currentStep > 0 && (
        <OrderJourneyContent wide>
          <div className="mb-md">
            <Button
              text={translations['orderJourney.back'] || ''}
              variation="text"
              onClick={previousHandler}
              iconName="arrow-left"
              iconPosition="left"
              animation="shiftLeft"
            />
          </div>
        </OrderJourneyContent>
      )}
      <Wrapper {...wrapperProps}>
        <Page.Main>
          {questions.map((question) => {
            const Component = QuestionComponents[question.component]
            return (
              <Component
                key={question.id}
                // temp solution while v1 and v2 use different props to update context:
                contextKey={question.key}
                question={question}
                context={ctx}
                dispatch={updateContext}
                nextPage={nextPage}
                prevPage={previousHandler}
                setCustomNextHandler={setCustomNextHandler}
                setContinueEnabled={setContinueEnabled}
                setStatusText={setStatusText}
              />
            )
          })}

          {TopModuleComponent && (
            <Page.Section backgroundColour="/white" decoration="/curve/quarter/left">
              <Page.Content>
                <TopModuleComponent {...topModule} />
              </Page.Content>
            </Page.Section>
          )}

          {bottomModules.length > 0 && (
            <Page.Section>
              {bottomModules?.map((module) => {
                const Component = moduleMap[module.contentType] || null

                if (!Component) return null

                return (
                  <div key={module.id} className="mb-lg">
                    <Page.Content>
                      <Component {...module} />
                    </Page.Content>
                  </div>
                )
              })}
            </Page.Section>
          )}

          {showDebug && <DebugPanel context={ctx} />}
        </Page.Main>

        {(showContinueButton || showPlaceOrderButton) && (
          <OrderJourneyContent wide>
            <div className="mb-md">
              <Divide />
              <PullRight>
                <Button
                  type="submit"
                  text={continueText}
                  onClick={nextHandler}
                  iconName="arrow-right"
                  animation="shiftRight"
                  disabled={!continueEnabled}
                  fullWidthOnMobile
                />
              </PullRight>
            </div>
          </OrderJourneyContent>
        )}

        {showBasket && <input type="submit" hidden />}
      </Wrapper>

      {showBasket && (
        <Basket
          context={ctx}
          dispatch={updateContext}
          nextPage={nextHandler}
          continueText={continueText}
          statusText={statusText}
          fixed
        />
      )}
    </>
  )

  return (
    <>
      {journey.seo && <SEO seo={journey.seo} url={journey.url} Head={Head} />}
      {cobrandingLogo && (
        <OrderJourneyContent wide>
          <CobrandingLogo logo={cobrandingLogo} alt={cobrandingAlt} />
        </OrderJourneyContent>
      )}
      {!step?.hideProgressBar && (
        <OrderJourneyContent wide>
          <ProgressBar sections={sectionNames} current={currentSectionTitle} />
        </OrderJourneyContent>
      )}
      {isStep ? defaultView : loadingView}
    </>
  )
}

export default Template
