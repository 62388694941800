import styled from 'styled-components'
import useTranslations from '../../utils/use-translations'

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: ${({ theme }) => `-${theme?.spacing?.base}`};
`

const Img = styled.img`
  height: 30px;
`

const CobrandingLogo = ({ logo, alt }) => {
  const translations = useTranslations()
  const suffix = translations['orderJourney.cobrandingSuffix']

  return (
    <Wrapper className="pb-md pl-sm">
      <span className="body-sm pr-sm">{suffix}</span>
      <Img src={`/images/cobranding/${logo}`} alt={alt} />
    </Wrapper>
  )
}

export default CobrandingLogo
