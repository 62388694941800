import { useEffect, useState } from 'react'
import { PhotoUploadField, OrderJourneyContent } from '@sh24/ui-components'
import getQuestionInfo from '../../utils/get-question-info'
import useTranslations from '../../utils/use-translations'

export const MAX_MB = 20
export const MAX_DIMENSION = 5_000
const ALLOWED_FILE_TYPES = [
  'image/jpeg',
  'image/png',
]

const imageDimensions = (file) => new Promise((resolve) => {
  const img = new Image()
  img.onload = () => {
    resolve({ naturalWidth: img.naturalWidth, naturalHeight: img.naturalHeight })
    URL.revokeObjectURL(img.src)
  }
  img.src = URL.createObjectURL(file)
})

const validateFile = async (file, messages) => {
  const errors = []

  const dimensions = await imageDimensions(file)

  if (file.size > (MAX_MB * 1024 * 1024)) errors.push(messages.fileSize)
  if (dimensions.naturalWidth > MAX_DIMENSION) errors.push(messages.fileWidth)
  if (dimensions.naturalHeight > MAX_DIMENSION) errors.push(messages.fileHeight)
  if (!ALLOWED_FILE_TYPES.includes(file.type)) errors.push(messages.fileType)

  return errors
}

const PhotoUploadQuestion = ({
  question,
  contextKey,
  context,
  dispatch,
  nextPage,
  setCustomNextHandler,
  setContinueEnabled,
}) => {
  const translations = useTranslations()
  const data = context[contextKey]
  const uuid = context[`${contextKey}_uuid`]
  const description = context[`${contextKey}_description`]
  const { errorMessages } = context
  const [tempFile, setTempFile] = useState(null)
  const { infoTop, infoBottom } = getQuestionInfo(context, question)
  const errors = context?.errors?.[contextKey] || []
  const previewSrc = tempFile ? URL.createObjectURL(tempFile) : null
  const successMessage = data?.uploaded ? translations['orderJourney.photoSuccessfullyUploaded'] : null
  const uploadLabel = data?.uploaded ? translations['orderJourney.changePhoto'] : question.label

  const handleUploadThenNext = async (e) => {
    e.preventDefault()

    if (tempFile) {
      setContinueEnabled(false)

      const fileErrors = await validateFile(tempFile, errorMessages)
      if (fileErrors.length) {
        setContinueEnabled(true)
        dispatch('errors', {
          [contextKey]: fileErrors,
        })
        return
      }

      const formData = new FormData()
      formData.append('file', tempFile)
      if (uuid) formData.append('uuid', uuid)

      const res = await fetch('/api/upload-gd-photo', {
        method: 'POST',
        body: formData,
      })

      const resJson = await res.json()

      if (res.status === 200) {
        const {
          uuid: serveruuid,
          type,
          fileName,
        } = resJson

        dispatch(contextKey, {
          ...data,
          type,
          fileName,
          uploaded: true,
        })

        dispatch(`${contextKey}_uuid`, serveruuid)

        nextPage(e)
      } else {
        setContinueEnabled(true)
        dispatch('errors', {
          [contextKey]: resJson.errors.map((err) => err.message),
        })
      }
    } else {
      nextPage(e)
    }
  }

  useEffect(() => {
    setCustomNextHandler(() => handleUploadThenNext)
    return () => setCustomNextHandler(null)
  }, [tempFile, data])

  const handleFileChange = async (e) => {
    const [file] = e.target.files || []
    if (file) {
      setTempFile(file)
      dispatch(contextKey, null)
    }
  }

  const handleRemoveFile = () => {
    setTempFile(null)
    dispatch(contextKey, null)
  }

  const handleDescriptionChange = (e) => {
    dispatch(`${contextKey}_description`, e.target.value)
  }

  return (
    <OrderJourneyContent>
      <PhotoUploadField
        id={question.id}
        title={question.title}
        infoTop={infoTop}
        infoBottom={infoBottom}
        uploadLabel={uploadLabel}
        onFileChange={handleFileChange}
        previewSrc={previewSrc}
        description={description || ''}
        descriptionLabel={translations['field.photoDescription'] || ''}
        onDescriptionChange={handleDescriptionChange}
        removeLabel={translations['orderJourney.removePhoto'] || ''}
        onRemoveClick={handleRemoveFile}
        successMessage={successMessage}
        errors={errors}
      />
    </OrderJourneyContent>
  )
}

export default PhotoUploadQuestion
