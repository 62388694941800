import { useState } from 'react'
import { TextField, OrderJourneyContent } from '@sh24/ui-components'
import getQuestionInfo from '../../utils/get-question-info'

const DateTime = ({
  question,
  contextKey,
  context,
  dispatch,
}) => {
  const defaultValue = () => {
    if (context[contextKey]) {
      const currentDateTimeValue = new Date(context[contextKey])
      const day = `0${currentDateTimeValue.getDate()}`.slice(-2)
      const month = `0${currentDateTimeValue.getMonth() + 1}`.slice(-2)
      const year = currentDateTimeValue.getFullYear()
      const hour = `0${currentDateTimeValue.getHours()}`.slice(-2)
      const minutes = `0${currentDateTimeValue.getMinutes()}`.slice(-2)
      const seconds = `0${currentDateTimeValue.getSeconds()}`.slice(-2)

      // formatted as YYYY-MM-DDTHH:MM
      return `${year}-${month}-${day}T${hour}:${minutes}:${seconds}`
    }

    return ''
  }

  const errors = context?.errors?.[contextKey] || []
  const { infoTop, infoBottom } = getQuestionInfo(context, question)

  const [dateTimeValue, setDateTimeValue] = useState(defaultValue)

  const handler = (e) => {
    const inputtedDateTimeValue = e.target.value
    setDateTimeValue(inputtedDateTimeValue)
    if (e.target.value.length > 0) {
      return dispatch(contextKey, new Date(inputtedDateTimeValue).toISOString())
    }

    return dispatch(contextKey, inputtedDateTimeValue)
  }

  return (
    <OrderJourneyContent>
      <TextField
        id={question.id}
        title={question.title}
        label={question.label}
        infoTop={infoTop}
        infoBottom={infoBottom}
        type="datetime-local"
        onChange={handler}
        value={dateTimeValue}
        errors={errors}
      />
    </OrderJourneyContent>
  )
}

export default DateTime
