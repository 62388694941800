import { useState } from 'react'
import {
  Callout,
  ProductSelectionCard,
  Grid,
} from '@sh24/ui-components'
import Image from '../Image/image'
import documentToReactComponents from '../../utils/document-to-react-components'
import Header from './header'
import { buildProduct } from '../../order-journeys/_v2/helpers'

const ProductPreview = ({ product }) => {
  const ProductImage = product.image ? (
    <Image
      image={product.image}
      maxWidth={350}
      maxHeight={200}
      layout="intrinsic"
    />
  ) : null

  const [selectedOption, setSelectedOption] = useState(product.options?.find((opt) => opt.selected))
  const hint = product.hint || selectedOption?.hint
  const hintIcon = product.hintIcon || selectedOption?.hintIcon
  const price = (selectedOption?.price ? selectedOption?.formattedPrice : null)
  || (product.price ? product.formattedPrice : null)
  const options = product.options?.map((opt) => ({
    text: opt.label,
    value: opt.identifier,
    selected: opt.identifier === selectedOption.identifier,
  }))

  const handleOptionChange = (e) => {
    setSelectedOption(product.options?.find((opt) => opt.identifier === e.value))
  }

  return (
    <ProductSelectionCard
      key={product.identifier}
      id={product.identifier}
      title={product.productName}
      byline={product.byline}
      motif={product.motif}
      image={ProductImage}
      price={price || null}
      details={documentToReactComponents(product.details)}
      keyFeatures={product.keyFeatures}
      contentSummary={product.contentSummary}
      options={options || null}
      optionsLabel={product.optionsLabel || null}
      onOptionChange={handleOptionChange}
      selected={false}
      hint={hint || null}
      hintIcon={hintIcon || null}
      cta={null}
    />
  )
}

const Products = ({ products }) => {
  if (!products || products.length === 0) {
    return (
      <Callout
        title="WARNING"
        content={<p>No products have been configured for this journey.</p>}
        icon="alert"
        backgroundColour="/errorFill"
        iconBackgroundColour="/white"
      />
    )
  }

  const [open, setOpen] = useState(false)
  const prodsWithPrices = products.map((product) => buildProduct({
    product,
    currency: 'GBP',
    locale: 'en-GB',
  }))

  return (
    <>
      <Header
        text={`${products.length} product${products.length > 1 ? 's' : ''}`}
        initialOpen={false}
        callback={() => setOpen(!open)}
      />
      {open && (
        <div className="mt-md">
          <Grid width="full-width" mobileColumns="1" tabletColumns="2" desktopColumns="2">
            {prodsWithPrices.map((prod) => <ProductPreview key={prod.identifier} product={prod} />)}
          </Grid>
        </div>
      )}
    </>
  )
}

export default Products
