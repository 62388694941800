import { useEffect } from 'react'
import { DobField, OrderJourneyContent } from '@sh24/ui-components'
import getQuestionInfo from '../../utils/get-question-info'

const DobQuestion = ({
  question,
  contextKey,
  context,
  dispatch,
}) => {
  const dob = context[contextKey]
  const errors = context?.errors || {}
  const { infoTop, infoBottom } = getQuestionInfo(context, question)

  useEffect(() => {
    if (!dob) {
      dispatch(contextKey, {
        dd: '',
        mm: '',
        yyyy: '',
      })
    }
  }, [])

  const handler = (e) => {
    dispatch(contextKey, {
      ...dob,
      [e.target.id]: e.target.value.substring(0, e.target.maxLength),
    })
  }

  return (
    <OrderJourneyContent>
      <DobField
        id={contextKey}
        title={question.title}
        label={question.label}
        dob={dob}
        onChange={handler}
        errors={errors}
        infoTop={infoTop}
        infoBottom={infoBottom}
      />
    </OrderJourneyContent>
  )
}

export default DobQuestion
