import { useState } from 'react'
import { PhoneNumberField, OrderJourneyContent } from '@sh24/ui-components'
import { parsePhoneNumber } from 'libphonenumber-js/mobile'
import useTranslations from '../../utils/use-translations'
import getQuestionInfo from '../../utils/get-question-info'
import redactedPhoneNumber from '../../utils/redacted-phone-number'

const PhoneNumberQuestion = ({
  question,
  contextKey,
  context,
  dispatch,
  saveRedacted = true,
}) => {
  const translations = useTranslations()
  const phoneRegions = context.phone_regions
  const { infoTop, infoBottom } = getQuestionInfo(context, question)

  const initialCountryCodeValue = () => {
    if (context[contextKey]?.countryCode) {
      return context[contextKey].countryCode
    }

    if (phoneRegions?.length) {
      return phoneRegions[0]
    }

    return 'GB'
  }
  const [countryCodeValue, setCountryCodeValue] = useState(initialCountryCodeValue)

  const defaultValue = {
    input: '',
    formattedNumber: '',
    countryCode: countryCodeValue,
  }
  const value = context[contextKey] || defaultValue
  const errors = context?.errors?.[contextKey] || []

  const formatPhoneNumber = ({ number, countryCode }) => {
    try {
      const parsedNumber = parsePhoneNumber(number, countryCode)
      return parsedNumber.format('E.164')
    } catch {
      // Number is probably incomplete. Validation should be performed by state machine
      return ''
    }
  }

  const handler = (e) => {
    const input = e.target.value
    const formattedNumber = formatPhoneNumber({
      number: input,
      countryCode: countryCodeValue,
    })

    dispatch(contextKey, { ...value, input, formattedNumber })
    if (saveRedacted) {
      dispatch(`${contextKey}Redacted`, redactedPhoneNumber(formattedNumber))
    }
  }

  const countryCodeHandler = (e) => {
    const countryCodeValueFromInput = e.value
    const formattedNumber = formatPhoneNumber({
      number: value.input,
      countryCode: countryCodeValueFromInput,
    })

    dispatch(contextKey, { ...value, countryCode: countryCodeValueFromInput, formattedNumber })
    setCountryCodeValue(countryCodeValueFromInput)
  }

  return (
    <OrderJourneyContent>
      <PhoneNumberField
        id={question.id}
        title={question.title}
        infoTop={infoTop}
        infoBottom={infoBottom}
        label={question.label}
        onChange={handler}
        value={value.input}
        phoneRegions={phoneRegions}
        countryCodeValue={countryCodeValue}
        countryCodeAriaLabel={translations['field.countryCode']}
        onCountryCodeChange={countryCodeHandler}
        errors={errors}
      />
    </OrderJourneyContent>
  )
}

export default PhoneNumberQuestion
