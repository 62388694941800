import { useState, useEffect } from 'react'
import {
  PaymentRequestButtonElement,
  useStripe,
} from '@stripe/react-stripe-js'
import { FieldError } from '@sh24/ui-components'
import {
  initialisePaymentRequest,
  handleCardPayment,
} from '../../services/payments'
import useTranslations from '../../utils/use-translations'
import { getPaymentMethod } from '../../utils/helpers'

/**
 * Implements the Payment Request Button API
 *
 * For full details read the docs: https://stripe.com/docs/stripe-js/elements/payment-request-button?html-or-react=react#react-complete-payment
 */
const AppleGoogle = ({
  amount,
  nextPage,
  paymentIntent,
  dispatch,
}) => {
  const stripe = useStripe()
  const [paymentRequest, setPaymentRequest] = useState(null)
  const [error, setError] = useState(null)
  const [supported, setSupported] = useState(true)
  const [paymentMethod, setPaymentMethod] = useState('')
  const translations = useTranslations()

  const updateContext = (intent) => {
    dispatch('paymentIntent', {
      ...intent,
      paymentMethod,
    })
  }

  useEffect(() => {
    const updatePaymentRequest = async () => {
      if (stripe) {
        const pr = initialisePaymentRequest({ stripe, currency: 'GBP', amount })
        const supportedOptions = await pr.canMakePayment()
        if (supportedOptions) {
          setPaymentRequest(pr)
          setPaymentMethod(getPaymentMethod(supportedOptions))
        } else {
          setSupported(false)
        }
      }
    }
    updatePaymentRequest()
  }, [stripe])

  useEffect(() => {
    if (paymentIntent && paymentRequest && paymentMethod) {
      paymentRequest.on('paymentmethod', async (event) => {
        try {
          const resolvedPaymentIntent = await handleCardPayment({
            stripe,
            paymentIntent,
            paymentMethod: event.paymentMethod.id,
            options: {
              handleActions: false,
            },
          })

          event.complete('success')

          if (resolvedPaymentIntent.status === 'requires_action') {
            const {
              paymentIntent: actionedPaymentIntent,
              error: stripeError,
            } = await stripe.confirmCardPayment(paymentIntent.client_secret)

            if (stripeError) {
              setError(stripeError?.message)
            } else {
              updateContext(actionedPaymentIntent)
              nextPage()
            }
          } else {
            updateContext(resolvedPaymentIntent)
            nextPage()
          }
        } catch (payload) {
          event.complete('fail')
          setError(payload?.error?.message)
        }
      })
    }
  }, [paymentIntent, paymentRequest, paymentMethod])

  if (paymentRequest && paymentIntent) {
    return (
      <>
        <PaymentRequestButtonElement options={{ paymentRequest }} />
        <div className="mt-md" aria-live="polite">
          {error && <FieldError>{error}</FieldError>}
        </div>
      </>
    )
  }

  return (
    <div className="mt-md">
      <p>
        {supported ? translations['orderJourney.loading'] : translations['paymentPage.notSupported']}
      </p>
    </div>
  )
}

export default AppleGoogle
