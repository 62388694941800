import { BLOCKS } from '@contentful/rich-text-types'
import { imageFactory } from '@sh24/contentful-service/factories'
import { ButtonLink } from '@sh24/ui-components'
import Image from '../../components/Image/image'
import InlineLink from '../../components/InlineLink/inline-link'
import processText from '../process-text'
import renderNode from './render-node'

export const questionOptionBlockNodesMap = {
  contentLink: (target) => (
    <ButtonLink key={target.id} variation={target.linkStyle}>
      <InlineLink {...target} iconName="external-link" context="content" />
    </ButtonLink>
  ),
}

const renderAsset = (node) => {
  const { data } = node
  const unsupported = ['application/pdf']
  const image = imageFactory(data.target)
  if (unsupported.includes(image.mimeType)) return null
  return image?.src ? (
    <div className="mt-base">
      <Image
        image={image}
        maxHeight={120}
        maxWidth={215}
        quality="75"
        layout="fixed"
      />
    </div>
  ) : null
}

const questionOptionOptions = {
  renderNode: {
    [BLOCKS.EMBEDDED_ASSET]: renderAsset,
    [BLOCKS.EMBEDDED_ENTRY]: renderNode(questionOptionBlockNodesMap),
  },
  renderText: processText,
}

export default questionOptionOptions
