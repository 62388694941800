import { TextField, OrderJourneyContent } from '@sh24/ui-components'
import getQuestionInfo from '../../utils/get-question-info'

const TextQuestion = ({
  question,
  contextKey,
  dispatch,
  context = {},
}) => {
  const value = context[contextKey] || ''
  const errors = context.errors?.[contextKey] || []
  const { infoTop, infoBottom } = getQuestionInfo(context, question)
  const handler = (e) => {
    dispatch(contextKey, e.target.value)
  }

  return (
    <OrderJourneyContent>
      <TextField
        id={question.id}
        title={question.title}
        autoComplete={question.autoComplete}
        label={question.label}
        hideLabel={question.hideLabel}
        infoTop={infoTop}
        infoBottom={infoBottom}
        type={question.type}
        value={value}
        placeholder={question.placeholder}
        onChange={handler}
        suffix={question.suffix}
        width={question.width}
        {...question.props}
        errors={errors}
      />
    </OrderJourneyContent>
  )
}

export default TextQuestion
