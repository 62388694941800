import styled from 'styled-components'
import { OrderJourneyContent } from '@sh24/ui-components'

const Code = styled.pre`
  font-size: 1.6rem;
  color: grey;
  overflow: scroll;
`

const DebugPanel = ({ context }) => {
  const {
    journey,
    basket,
    errors,
    errorMessages,
    validationLabels,
    products,
    discountSettings,
    ...rest
  } = context

  delete rest.deliveryAddress_addressList
  delete rest.billingAddress_addressList

  const translations = {
    errorMessages,
    validationLabels,
  }

  return (
    <OrderJourneyContent>
      <details>
        <summary>Debug</summary>
        <details className="ml-md mt-md">
          <summary>Verification code</summary>
          <Code role="code">
            {context.verificationCode || 'not set'}
          </Code>
        </details>

        <details className="ml-md mt-md">
          <summary>Basket</summary>
          <Code role="code">
            {basket ? JSON.stringify(basket, undefined, 2) : 'no basket'}
          </Code>
        </details>

        <details className="ml-md mt-md">
          <summary>Context</summary>
          <Code role="code">
            {JSON.stringify(rest, undefined, 2)}
          </Code>
        </details>

        <details className="ml-md mt-md">
          <summary>Errors</summary>
          <Code role="code">
            {errors ? JSON.stringify(errors, undefined, 2) : 'no errors'}
          </Code>
        </details>

        <details className="ml-md mt-md">
          <summary>Journey</summary>
          <Code role="code">
            {JSON.stringify(journey, undefined, 2)}
          </Code>
        </details>

        <details className="ml-md mt-md">
          <summary>Products</summary>
          <Code role="code">
            {products ? JSON.stringify(products, undefined, 2) : 'no products'}
          </Code>
        </details>

        <details className="ml-md mt-md">
          <summary>Discounts</summary>
          <Code role="code">
            {discountSettings ? JSON.stringify(discountSettings, undefined, 2) : 'no discounts'}
          </Code>
        </details>

        <details className="ml-md mt-md">
          <summary>Translations</summary>
          <Code role="code">
            {translations ? JSON.stringify(translations, undefined, 2) : 'no translations'}
          </Code>
        </details>
      </details>

    </OrderJourneyContent>
  )
}

export default DebugPanel
