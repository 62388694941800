import { TextAreaField, OrderJourneyContent } from '@sh24/ui-components'
import getQuestionInfo from '../../utils/get-question-info'

const TextAreaQuestion = ({
  question,
  contextKey,
  context = {},
  dispatch,
}) => {
  const value = context[contextKey] || ''
  const errors = context?.errors?.[contextKey] || []
  const { infoTop, infoBottom } = getQuestionInfo(context, question)
  const onChange = (e) => {
    dispatch(contextKey, e.target.value)
  }

  return (
    <OrderJourneyContent>
      <TextAreaField
        id={question.id}
        title={question.title}
        autoComplete={question.autoComplete}
        infoTop={infoTop}
        infoBottom={infoBottom}
        label={question.label}
        value={value}
        rows={4}
        onChange={onChange}
        errors={errors}
      />
    </OrderJourneyContent>
  )
}

export default TextAreaQuestion
