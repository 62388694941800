import { SelectField, OrderJourneyContent } from '@sh24/ui-components'
import ETHNICITIES_MAPPINGS from './ethnicity-options-map'
import getQuestionInfo from '../../../utils/get-question-info'

const ETHNICITIES_OPTIONS = ETHNICITIES_MAPPINGS[process.env.NEXT_PUBLIC_SITE]

const EthnicityDropdownQuestion = ({
  question,
  contextKey,
  context,
  dispatch,
}) => {
  const errors = context?.errors?.[contextKey] || []
  const selectedEthnicityValue = context[contextKey]
  const flatOptions = ETHNICITIES_OPTIONS.flatMap((group) => group.value)
  const selectedOption = flatOptions.find((ethnicity) => ethnicity.value === selectedEthnicityValue)
  const selectedEthnicityOption = selectedOption
    ? {
      label: selectedOption.text,
      value: selectedOption.value,
    }
    : null
  const { infoTop, infoBottom } = getQuestionInfo(context, question)

  const onChange = (payload) => {
    dispatch(contextKey, payload?.value)
  }

  return (
    <OrderJourneyContent>
      <SelectField
        id={question.id}
        title={question.title}
        label={question.label}
        infoTop={infoTop}
        infoBottom={infoBottom}
        value={selectedEthnicityOption}
        options={ETHNICITIES_OPTIONS}
        onChange={onChange}
        showDropdown={question.showDropdown}
        errors={errors}
      />
    </OrderJourneyContent>
  )
}

export default EthnicityDropdownQuestion
