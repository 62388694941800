import styled from 'styled-components'
import { useState } from 'react'
import { PayPalScriptProvider, PayPalButtons } from '@paypal/react-paypal-js'

const Container = styled.div`
  min-height: 72px; // to reduce layout shift.
`

const PaypalForm = ({ total, callBack }) => {
  const [error, setError] = useState()

  const initialOptions = {
    'client-id': process.env.NEXT_PUBLIC_PAYPAL_CLIENT_ID,
    currency: 'GBP',
    intent: 'capture',
  }

  const createOrder = (data, actions) => (
    actions.order.create({
      purchase_units: [{
        amount: {
          value: total,
        },
      }],
    })
  )

  const showError = (err) => {
    setError(err)
  }

  return (
    <Container>
      <PayPalScriptProvider options={initialOptions}>
        <PayPalButtons createOrder={createOrder} onError={showError} onApprove={callBack} style={{ layout: 'horizontal' }} />
      </PayPalScriptProvider>
      {error && <div>{error}</div>}
    </Container>
  )
}

export default PaypalForm
