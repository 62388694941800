import { OrderJourneyContent, Toggle } from '@sh24/ui-components'

const PreviewModeToggle = ({ view, setView }) => {
  const toggleOptions = [
    { id: 'journey', label: 'Journey view' },
    { id: 'summary', label: 'Summary view' },
  ]

  return (
    <OrderJourneyContent wide>
      <Toggle
        options={toggleOptions}
        selected={view}
        onChange={(e) => setView(e.target.id)}
      />
    </OrderJourneyContent>
  )
}

export default PreviewModeToggle
