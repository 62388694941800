import styled from 'styled-components'
import Conditions from '../preview/conditions'
import Header from './header'

const Container = styled.div`
${({ theme }) => `
    border: 2px solid ${theme.palette.black500};
    padding: ${theme.spacing.base};
    background-color: ${theme.palette.successFill};
  `}
`

const Service = ({ service }) => (
  <Container className="mb-base ml-base">
    <Header
      text={service.service}
      initialOpen={false}
      withToggle={service.conditions?.length > 0}
    />
    {service.conditions && (
      <div className="mt-base">
        <Conditions conditions={service.conditions} />
      </div>
    )}
  </Container>
)

export default Service
