import TextQuestion from './text-question'
import DobQuestion from './dob-question'
import RadioQuestion from './radio-question'
import CheckboxQuestion from './checkbox-question'
import Section from './section'
import TextAreaQuestion from './text-area-question'
import DateQuestion from './date-question'
import DateTimeQuestion from './date-time-question'
import BloodPressureQuestion from './blood-pressure-question'
import AddressLookupQuestion from './address-lookup-question'
import WeightQuestion from './weight-question'
import HeightQuestion from './height-question'
import EmailQuestion from './email-question'
import PhoneNumberQuestion from './phone-number-question'
import VerificationCodeQuestion from './verification-code-question'
import NumberQuestion from './number-question'
import PartnerPhoneNumbers from './partner-phone-numbers'
import CountriesDropdownQuestion from './CountryDropdownQuestion/countries-dropdown-question'
import EthnicityDropdownQuestion from './EthnicityDropdownQuestion/ethnicity-dropdown-question'
import AccountOptions from './account-options'
import ProductSelection from './product-selection'
import Checkout from './checkout'
import PhotoUploadQuestion from './photo-upload-question'

export default {
  accountOptions: AccountOptions,
  addressLookup: AddressLookupQuestion,
  addressLookupPrefilled: AddressLookupQuestion,
  bloodPressure: BloodPressureQuestion,
  check: CheckboxQuestion,
  checkbox: CheckboxQuestion,
  checkout: Checkout,
  countriesDropdown: CountriesDropdownQuestion,
  date: DateQuestion,
  datetime: DateTimeQuestion,
  dob: DobQuestion,
  email: EmailQuestion,
  ethnicityDropdown: EthnicityDropdownQuestion,
  height: HeightQuestion,
  number: NumberQuestion,
  partnerPhoneNumbers: PartnerPhoneNumbers,
  phonenumber: PhoneNumberQuestion,
  photoUpload: PhotoUploadQuestion,
  productSelectionExtras: ProductSelection,
  productSelectionPrimary: ProductSelection,
  productSelectionSecondary: ProductSelection,
  radio: RadioQuestion,
  section: Section,
  text: TextQuestion,
  textarea: TextAreaQuestion,
  verificationCode: VerificationCodeQuestion,
  weight: WeightQuestion,
}
