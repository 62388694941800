import styled from 'styled-components'
import Conditions from '../preview/conditions'
import Header from './header'

const Container = styled.div`
  ${({ theme }) => `
    border: 2px solid ${theme.palette.black500};
    padding: ${theme.spacing.base};
    margin-bottom: ${theme.spacing.base};
    background-color: ${theme.palette.errorFill};
  `}
`

const Kickout = ({ kickout }) => (
  <Container isKickout key={kickout.id}>
    <Header text={kickout.id} initialOpen={false} />
    {kickout.conditions && (
      <div className="mt-base">
        <Conditions conditions={kickout.conditions} />
      </div>
    )}
  </Container>
)

export default Kickout
