import styled from 'styled-components'

const Box = styled.div`
  background-color: #f6f6f6;
  border: 1px solid #313738;
  height: 40px;
  width: 300px;
  max-width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

const Widget = ({ question }) => (
  <>
    <strong>{question.label}</strong>
    <Box className="mt-xs">
      {question.component} widget.
    </Box>
  </>
)

export default Widget
