import { useState, Fragment } from 'react'
import {
  Basket as BasketComponent,
  BasketSummary,
  Button,
} from '@sh24/ui-components'
import FocusTrap from 'focus-trap-react'
import { inBasket, getBasketTotals } from '../../order-journeys/_v2/helpers'
import useTranslations from '../../utils/use-translations'
import BasketItem from './basket-item'
import RemovedBasketItem from './removed-basket-item'
import Discount from '../Discount/discount'

const Basket = ({
  context,
  dispatch,
  nextPage,
  continueText,
  statusText,
  fixed,
  FocusTrapComponent = FocusTrap,
}) => {
  const translations = useTranslations()
  const {
    orderType,
    products,
    basket,
    totals,
  } = context
  const [basketOpen, setBasketOpen] = useState(false)
  const [removedProduct, setRemovedProduct] = useState(null)
  const basketHasValue = totals?.basketTotal?.amount > 0

  const toggleBasket = (e) => {
    e?.preventDefault()
    setRemovedProduct(null)
    setBasketOpen(!basketOpen)
  }

  const nextHandler = (e) => {
    e?.preventDefault()
    setRemovedProduct(null)
    setBasketOpen(false)
    nextPage(e)
  }

  const buildItem = (item) => {
    const Component = item.identifier === removedProduct
      ? RemovedBasketItem
      : BasketItem

    return (
      <Component
        key={item.identifier}
        item={item}
        context={context}
        dispatch={dispatch}
        translations={translations}
        setRemovedProduct={setRemovedProduct}
      />
    )
  }

  const mainItems = products.filter((prod) => (
    !prod.isExtra
    && (inBasket({ basket, identifier: prod.identifier }) || removedProduct === prod.identifier)
  )).map(buildItem)

  const extraItems = products.filter((prod) => (
    prod.isExtra
    && (inBasket({ basket, identifier: prod.identifier }) || removedProduct === prod.identifier)
  )).map(buildItem)

  const ExtrasHeading = extraItems.length
    ? <p key="extras-heading" className="body-sm mt-md mb-base">{translations['orderJourney.basketExtras']}</p>
    : null

  const removePromoCta = (
    <Button
      text={translations.remove}
      ariaLabel={translations['orderJourney.promo.remove']}
      onClick={() => dispatch('discount', {})}
      variation="text"
      small
    />
  )

  const basketTotals = getBasketTotals({
    totals,
    translations,
    promoCta: removePromoCta,
  })

  const Wrapper = fixed ? FocusTrapComponent : Fragment
  const wrapperProps = fixed ? {
    active: basketOpen,
    focusTrapOptions: {
      initialFocus: false,
      escapeDeactivates: false,
    },
  } : null

  return (
    <Wrapper {...wrapperProps}>
      <div>
        <BasketComponent
          closeAriaLabel={translations['orderJourney.closeBasket']}
          onClose={toggleBasket}
          basketItems={[
            ...mainItems,
            ExtrasHeading,
            ...extraItems,
          ]}
          totals={basketTotals}
          promoForm={basketHasValue && totals?.promoDiscount?.amount === 0
            ? <Discount orderType={orderType} dispatch={dispatch} />
            : null}
          fixed={fixed}
          open={basketOpen}
        />
        {fixed && (
          <BasketSummary
            text={translations[totals?.grandTotal?.translationKey]}
            basketCount={basket.length}
            basketOpen={basketOpen}
            price={basketHasValue
              ? totals?.grandTotal?.formattedAmount
              : null}
            continueCta={(
              <Button
                type="submit"
                text={continueText}
                onClick={nextHandler}
                iconName="arrow-right"
                animation="shiftRight"
                fullWidthOnMobile
              />
            )}
            statusText={statusText}
            openCta={(
              <Button
                text={translations[`orderJourney.${basketOpen ? 'close' : 'open'}Basket`]}
                onClick={toggleBasket}
                variation="text"
                small
              />
          )}
          />
        )}
      </div>
    </Wrapper>
  )
}

export default Basket
