import { useEffect } from 'react'
import { OrderJourneyContent, HeightField } from '@sh24/ui-components'
import { useAppContext } from '../../contexts/app-context'
import { centimetersToFeetInches, feetInchesToCentimeters } from '../../utils/units-converters'
import getQuestionInfo from '../../utils/get-question-info'

const HeightQuestion = ({
  question,
  contextKey,
  context,
  dispatch,
}) => {
  const defaultUnit = 'metric'
  const { appContext } = useAppContext()
  const { translations } = appContext
  const height = context[contextKey] || {}
  const errors = context?.errors || {}
  const { infoTop, infoBottom } = getQuestionInfo(context, question)

  useEffect(() => {
    if (!height?.unit) {
      dispatch(contextKey, {
        unit: defaultUnit,
      })
    }
  }, [])

  const labels = {
    metricOption: translations['field.metricHeightOption'],
    imperialOption: translations['field.imperialHeightOption'],
    centimeters: translations['field.centimeters'],
    feet: translations['field.feet'],
    inches: translations['field.inches'],
  }

  const unitConverters = {
    imperial: feetInchesToCentimeters,
    metric: centimetersToFeetInches,
  }

  const handler = (e) => {
    // e.target.id is one of the following: 'imperial', 'metric', 'centimeters', 'feet', 'inches'
    const isUnitUpdate = ['imperial', 'metric'].includes(e.target.id)
    const key = isUnitUpdate ? 'unit' : e.target.id

    const updatedValues = {
      ...height,
      [key]: e.target.value,
    }

    const unitConverter = unitConverters[updatedValues.unit]
    const convertedValues = isUnitUpdate ? {} : unitConverter(updatedValues)

    dispatch(contextKey, {
      ...updatedValues,
      ...convertedValues,
    })
  }

  if (!height?.unit) return null

  return (
    <OrderJourneyContent>
      <HeightField
        id={contextKey}
        labels={labels}
        title={question.title}
        infoTop={infoTop}
        infoBottom={infoBottom}
        errors={errors}
        onChange={handler}
        height={height}
      />
    </OrderJourneyContent>
  )
}

export default HeightQuestion
