import { BasketItem, Button } from '@sh24/ui-components'
import replaceTranslationTokens from '../../utils/replace-translation-tokens'
import { trackAddToBasket } from '../../order-journeys/_v2/tracking'
import { addToBasket } from '../../order-journeys/_v2/helpers'

const RemovedBasketItem = ({
  item,
  context,
  dispatch,
  translations,
  setRemovedProduct,
}) => {
  const { basket, products } = context
  const statusText = replaceTranslationTokens({
    translations,
    translationKey: 'orderJourney.removedFromBasket',
    values: [item.productName],
  })

  const ctaAriaLabel = replaceTranslationTokens({
    translations,
    translationKey: 'orderJourney.addToBasket',
    values: [item.productName],
  })

  const addHandler = (e) => {
    e.preventDefault()
    setRemovedProduct(null)
    trackAddToBasket(item.identifier, context)
    dispatch('basket', addToBasket({ basket, products, identifier: item.identifier }))
  }

  return (
    <BasketItem
      text={statusText}
      removed
      cta={(
        <Button
          text={translations.undo}
          ariaLabel={ctaAriaLabel}
          onClick={addHandler}
          variation="text"
          small
        />
      )}
    />
  )
}

export default RemovedBasketItem
