import { useState } from 'react'
import styled from 'styled-components'
import { Icon } from '@sh24/ui-components'

const HeaderRow = styled.div`
  display: flex;
  justify-content: space-between;

  + div {
    display: none;
  }

  &.open + div {
    display: block;
  }
`

const HeaderButton = styled.button`
  border: 0 none;
  background-color: transparent;
  padding: 0;
  margin: 0;
  cursor: pointer;
`

const Header = ({
  text,
  initialOpen = true,
  withToggle = true,
  callback,
}) => {
  const [open, setOpen] = useState(withToggle ? initialOpen : true)

  const toggle = () => {
    setOpen(!open)
    if (typeof callback === 'function') callback()
  }

  return (
    <HeaderRow className={`${open ? 'open' : null}`}>
      <p className="mb-zero semi-bold">{text}</p>
      {withToggle && (
        <HeaderButton type="button" onClick={toggle}>
          <Icon name={open ? 'chevron-up' : 'chevron-down'} />
        </HeaderButton>
      )}
    </HeaderRow>
  )
}

export default Header
