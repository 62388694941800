import styled from 'styled-components'
import type { Condition, RichText } from '@sh24/contentful-service'
import Conditions from './conditions'
import documentToReactComponents from '../../utils/document-to-react-components'

const ConditionalTextBlockContainer = styled.div`
  border: 1px solid #ccc;
`

const ConditionalTextBlock = ({ content, conditions }: {
  content: RichText,
  conditions: Array<Condition>,
}) => (
  <ConditionalTextBlockContainer className="p-base mb-base">
    {conditions && (
      <div className="mb-base">
        <Conditions conditions={conditions} />
      </div>
    )}
    {documentToReactComponents(content)}
  </ConditionalTextBlockContainer>
)

export default ConditionalTextBlock
