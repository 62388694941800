const stringToInt = (string) => (string && string.length > 0 ? parseInt(string, 10) : 0)

const POUNDS_PER_KG = 2.20462262185
const INCHES_PER_CM = 0.393701

const kgToStonePounds = ({ kg }) => {
  const decimalPounds = stringToInt(kg) * POUNDS_PER_KG

  const stone = Math.floor(decimalPounds / 14)
  const pounds = Math.round(decimalPounds % 14)

  return {
    stone: stone.toString(),
    pounds: pounds.toString(),
  }
}

const stonePoundsToKg = ({ stone, pounds }) => {
  const totalPounds = (stringToInt(stone) * 14) + stringToInt(pounds)

  const kg = Math.round(totalPounds / POUNDS_PER_KG)

  return {
    kg: kg.toString(),
  }
}

const centimetersToFeetInches = ({ centimeters }) => {
  const decimalInches = stringToInt(centimeters) * INCHES_PER_CM
  const feet = Math.floor(decimalInches / 12)
  const inches = Math.round(decimalInches % 12)

  return {
    feet: feet.toString(),
    inches: inches.toString(),
  }
}

const feetInchesToCentimeters = ({ feet, inches }) => {
  const totalInches = (stringToInt(feet) * 12) + stringToInt(inches)

  const centimeters = Math.round(totalInches / INCHES_PER_CM)
  return {
    centimeters: centimeters.toString(),
  }
}

export {
  kgToStonePounds,
  stonePoundsToKg,
  centimetersToFeetInches,
  feetInchesToCentimeters,
}
