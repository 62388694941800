import { useState } from 'react'
import styled, { keyframes } from 'styled-components'
import {
  TextField,
  Button,
  Icon,
} from '@sh24/ui-components'
import useTranslations from '../../utils/use-translations'

const spinAnimation = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`

const LoadingWrapper = styled.span`
  animation: ${spinAnimation} 1s infinite ease;
  display: block;
  height: 48px;
`

const PromoForm = styled.form`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-top: ${({ theme }) => theme?.spacing?.md};
  margin-bottom: ${({ theme }) => theme?.spacing?.md};
`

const Discount = ({ orderType, dispatch }) => {
  const translations = useTranslations()
  const [promoCode, setPromoCode] = useState('')
  const [errors, setErrors] = useState([])
  const [processing, setProcessing] = useState(false)

  const fetchPromoCode = async () => {
    setProcessing(true)
    dispatch('discount', {})

    const res = await fetch(`/api/discount?code=${promoCode}&orderType=${orderType}`)
    const json = await res.json()

    if (json.data?.valid) {
      dispatch('discount', {
        code: promoCode.toUpperCase(),
        ...json.data.discount,
      })
      setPromoCode('')
    } else {
      setErrors([translations['errors.invalidPromoCode']])
    }

    setProcessing(false)
  }

  const onSubmit = (e) => {
    e.preventDefault()
    fetchPromoCode()
  }

  const onChangeHandler = (e) => {
    setErrors([])
    setPromoCode(e.target.value)
  }

  return (
    <PromoForm onSubmit={onSubmit}>
      <div className="mr-md">
        <TextField
          id="promoCode"
          label={translations['field.promoCode']}
          value={promoCode}
          onChange={onChangeHandler}
          errors={errors}
        />
      </div>
      <div className="mb-md">
        {!processing
          ? (
            <Button
              type="submit"
              text={translations['orderJourney.promoCode.apply']}
              iconName="arrow-right"
              onClick={onSubmit}
              variation="secondary"
            />
          ) : (
            <LoadingWrapper>
              <Icon name="loading" width={48} height={48} />
            </LoadingWrapper>
          )}
      </div>
    </PromoForm>
  )
}

export default Discount
