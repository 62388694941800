import styled from 'styled-components'
import Question from './question'
import Conditions from '../preview/conditions'
import Header from './header'
import { moduleMap } from '../../templates/order-journey-step-template.v2'

const Container = styled.div`
  ${({ theme }) => `
    border: 2px solid ${theme.palette.black500};
  `}
`

const Step = ({ step }) => (
  <Container key={step.id} className="p-base mb-base ml-base">
    <Header text={`/${step.slug}`} />
    <div>
      {step.conditions && (
        <div className="mt-base">
          <Conditions conditions={step.conditions} />
        </div>
      )}

      {step.questions.map((question) => (<Question key={question.id} question={question} />))}

      {step.modules.map((module) => {
        const Component = moduleMap[module.contentType]
        if (!Component) return null
        return <Component key={module.id} {...module} />
      })}
    </div>
  </Container>
)

export default Step
