import styled from 'styled-components'

const Condition = styled.pre`
  ${({ theme }) => `
    background-color: ${theme.palette.tertiary200};
    white-space: pre-wrap;
  `}
`

const Conditions = ({ conditions }) => {
  if (!conditions) return null

  return conditions.map((cond) => (
    <Condition key={cond.id} className="p-sm mt-zero pl-base pr-base mb-xs body-sm">
      IF <strong>{cond.contextKey}</strong> {cond.comparator} {cond.value?.toString()}
    </Condition>
  ))
}

export default Conditions
