import List from './list'
import BasicInput from './basic-input'
import Widget from './widget'

export default {
  accountOptions: Widget,
  addressLookup: Widget,
  addressLookupPrefilled: Widget,
  bloodPressure: Widget,
  check: List,
  checkbox: List,
  checkout: Widget,
  countriesDropdown: Widget,
  date: BasicInput,
  datetime: BasicInput,
  dob: Widget,
  email: BasicInput,
  ethnicityDropdown: Widget,
  height: Widget,
  number: BasicInput,
  partnerPhoneNumbers: Widget,
  phonenumber: BasicInput,
  photoUpload: Widget,
  productSelectionExtras: Widget,
  productSelectionPrimary: Widget,
  productSelectionSecondary: Widget,
  radio: List,
  text: BasicInput,
  textarea: BasicInput,
  verificationCode: BasicInput,
  weight: Widget,
}
